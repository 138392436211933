import { AuthService } from '../../services/auth.service';
import { RegionService } from '../../services/region.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-region-dependiente-selector',
  templateUrl: './region-dependiente-selector.component.html',
  styleUrls: ['./region-dependiente-selector.component.scss']
})
export class RegionDependienteSelectorComponent implements OnInit {
  @Input() disabled = false;
  @Input() region: any;
  @Input() regionData: any;
  @Output() regionChange = new EventEmitter<any>();

  // regionData;
  constructor(private regionService: RegionService, private authService: AuthService) {
    // this.regionData = authService.getCentros();
  }

  ngOnInit(): void {
  }
  changeFn() {
    this.regionChange.emit(this.region);
  }
}
