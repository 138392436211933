import { Obra } from '../model/obra.model';
import { Table } from '../model/table.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../utils/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RegionService {


  REGION = [
    {
      id: 1,
      text: 'Region I'
    },
    {
      id: 2,
      text: 'Region II'
    },
    {
      id: 3,
      text: 'Region III'
    },
    {
      id: 13,
      text: 'Region Metropolitana'
    },
  ];
  SERVICE_URL: string;

  constructor(private http: ConfigService) {
    this.SERVICE_URL = 'api/obra/';
  }

  getNombre(id: number) {
    return this.REGION.find(x => x.id == id)?.text;
  }
}
