import { Table } from './../../model/table.model';
import { AfterViewInit, Component, Input, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { SortableDirective, SortEvent } from '../../directives/sortable.directive';
import { TableProviderService } from '../../services/table-provider.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

declare var require;
const Swal = require('sweetalert2');

@Component({
  selector: 'app-admin-table',
  templateUrl: './admin-table.component.html',
  styleUrls: ['./admin-table.component.scss']
})
export class AdminTableComponent<T> implements OnInit, AfterViewInit {

  @ViewChildren(SortableDirective) headers: QueryList<SortableDirective<T>>;

  @Input() mapHeader;
  @Input() queryset;
  @Input() exportFile;
  @Input() extraCols: TemplateRef<any>;
  @Input() dtOptions: any;
  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;
  // dtOptions: any = {};
  modelCustom: NgbDateStruct;


  constructor(
    public service: TableProviderService<T>
  ) {
  }

  ngOnInit(): void {
    const columns = [];
    for (const key in this.mapHeader) {
      const value = this.mapHeader[key];
      columns.push({
        name: value.name ?? key,
        orderable: value.sort ?? true,
        data: value.data ?? function (d) {
          return d[key];
        }
      });
    }
    this.dtOptions = this.dtOptions ?? {
      language: {
        search: " Buscar:",
        paginate: {
          first: "Primero",
          previous: "Anterior",
          next: "Siguiente",
          last: "Último"
        },
        buttons: {
          colvis: 'Mostrar Columnas'
        },
        lengthMenu: "Mostrando _MENU_ entradas",
        infoEmpty: "Mostrando 0 entradas",
        zeroRecords: "No se encontraron registros coincidentes",
        emptyTable: "Datos no disponibles",
        info: "Mostrando _START_ a _END_ de _TOTAL_ entradas",
      },
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      columns,
      order: [['0', 'desc']],
      // dom: 'lfrBtip',
      dom: `<l<'d-flex justify-content-center'B><t>ip>`,
      buttons: [
        'colvis'
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        $('.btn-preview', row).off('click');
        $('.btn-preview', row).on('click', () => {
          const func = self.mapHeader.actions.preview ?? null;
          if (func !== null) {
            func(data);
          }
        });
        $('.btn-edit', row).off('click');
        $('.btn-edit', row).on('click', () => {
          const func = self.mapHeader.actions.edit ?? null;
          if (func !== null) {
            func(data);
          }
        });
        $('.btn-delete', row).off('click');
        $('.btn-delete', row).on('click', () => {
          const func = self.mapHeader.actions.delete ?? null;
          if (func !== null) {
            Swal.fire({
              title: '¿Estas seguro de realizar esta acción?',
              text: '¡Esta acción no podra ser revertida!',
              type: 'warning',
              // buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger',
              },
              showCancelButton: true,
              confirmButtonText: '¡Sí!',
              cancelButtonText: '¡Cancelar!',
              showLoaderOnConfirm: true,
              preConfirm: (code) => {
                return func(data).toPromise().then((e) => 1, (e) => 0);
              },
              allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
              if (result.isConfirmed) {
                if (result.value) {
                  this.reload();
                  Swal.fire({
                    title: ``,
                    text: `Proceso finalizado correctamente.`,
                    icon: `success`
                  });
                }
                else {
                  Swal.fire(
                    'Error en el servidor',
                    'Contactar con los administradores',
                    'error'
                  );
                }
              }
            });
          }
        });
        return row;
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.queryset(dataTablesParameters).subscribe((resp: Table<any>) => {
          callback({
            recordsTotal: resp.totalElements,
            recordsFiltered: resp.totalElements,
            data: resp.content
          });
        });
      },
    };
  }

  ngAfterViewInit(): void {
    // this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //   dtInstance.columns().every(function () {
    //     const that = this;
    //     $('input', this.footer()).on('keyup change', function () {
    //       if (that.search() !== this['value']) {
    //         that
    //           .search(this['value'])
    //           .draw();
    //       }
    //     });
    //     $('input.date-filter', this.footer()).on('change', function () {
    //       if (that.search() !== this['value']) {
    //         that
    //           .search(this['value'])
    //           .draw();
    //       }
    //     });
    //   });
    // });
  }
  filterSelectColumn(opt, index) {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      const col = dtInstance.columns(index);
      col.search(opt ? opt.id : '').draw();
    });
  }
  filterDateColumn(date: NgbDateStruct, index) {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      const col = dtInstance.columns(index);
      col.search(`${date.year}-${date.month}-${date.day} 00:00`).draw();
    });
  }
  filterColumn(search, index) {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      const col = dtInstance.columns(index);
      col.search(search.currentTarget.value).draw();
    });
  }
  onSort({ column, direction }: SortEvent<T>) {
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  reload() {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => dtInstance.draw());
  }

  compare(a, b) {
    return 0;
  }
}
