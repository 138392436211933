import { ProveedorService } from '../../services/proveedor.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Proveedor } from '../../model/proveedor.model';

const Swal = require('sweetalert2');
@Component({
  selector: 'app-proveedor-selector-bomba',
  templateUrl: './proveedor-selector-bomba.component.html',
  styleUrls: ['./proveedor-selector-bomba.component.scss']
})
export class ProveedorSelectorBombaComponent implements OnInit {
  centro = '';
  @Input() set setCentro(iCentro: string) {
    if (iCentro != null) {
      this.centro = iCentro;
      this.cargarProveedores();
    }
  }
  @Input() proveedor: any;
  @Input() disabled = false;
  @Output() proveedorChange = new EventEmitter<any>();
  @Output() minimoBombeo = new EventEmitter<any>();

  proveedorData: Proveedor[];
  constructor(private proveedorService: ProveedorService) { }

  ngOnInit(): void {
  }

  onClick() {
    if (this.centro) {
      if (this.proveedorData.length == 0) {
        this.alertaVacia();
      }
    } else {
      this.alertaSinPedido();
    }
  }

  cargarProveedores() {
    this.proveedorService.searchByCentro(this.centro).subscribe({
      next: (e: Proveedor[]) => {
        this.proveedorData = e;
        if (this.proveedorData.length != 0) {
          if (!this.proveedorData.find(x => x.id === this.proveedor)){
            this.proveedor = null;
          }
          this.changeFn();
        }
      }
    });

  }

  alertaVacia() {
    Swal.fire({
      title: `Proveedor`,
      text: ``,
      html: `No hay proveedores que presenten convenio con el centro.`,
      icon: `warning`,
    });
  }

  alertaSinPedido() {
    Swal.fire({
      title: `Proveedor`,
      text: ``,
      html: `No hay pedido seleccionado.`,
      icon: `warning`,
    });
  }

  changeFn() {
    this.proveedorChange.emit(this.proveedor);
    const minB = this.proveedorData.find(x => x.id === this.proveedor)?.minimoBombeo;
    this.minimoBombeo.emit(
      minB
    );
  }

}
