<button class="btn btn-cbb-b txt-cbb-wb" (click)="openModal()" type="button">{{btnText}}</button>

<ng-template #selectorModal let-modal>
  <div class="modal-header">
    <h4>{{btnText}}</h4>
  </div>
  <div class="modal-body text-center">
    <h5>{{title}}</h5>
    <div class="p-3">
      <ngx-dropzone (change)="onSelect($event)" [accept]="accept" [multiple]="multiple">
        <ngx-dropzone-label>Click para buscar o arrastre directamente el archivo</ngx-dropzone-label>
        <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
          <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
        </ngx-dropzone-preview>
      </ngx-dropzone>
    </div>
    <button class="btn btn-dark" *ngIf="template" (click)="onDownloadTemplate()"> Descargar plantilla</button>
    <div *ngIf="oldFiles.length">
      <label for="">Archivos Cargados</label>
      <div *ngFor="let item of oldFiles" >
        <a [href]="getUrlFile(item.id)" target="_blank">
          {{item.name}}
        </a>
      </div>
    </div>
  </div>

</ng-template>
