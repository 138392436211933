import { Table } from '../model/table.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Servicio } from '../model/service.model';
import { ConfigService } from '../utils/http';
import { SolicitudDespacho } from '../model/solicitud-despacho.model';

@Injectable({
  providedIn: 'root'
})
export class SolicitudDespachoService {
  SERVICE_URL: string;
  constructor(private http: ConfigService) {
    this.SERVICE_URL = 'api/despacho/';
  }
  getListDespacho(id: number) {
    return this.http.get<SolicitudDespacho[]>(`${this.SERVICE_URL}listar/${id}`);
  }
}
