<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
  <div class="header-wrapper row m-0">
    <div class="header-logo-wrapper">
      <div class="logo-wrapper">
        <a routerLink='/'>
          <img class="img-fluid for-light" src="assets/images/logo/logo.png" alt="">
          <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png" alt="">
        </a>
      </div>
      <div class="toggle-sidebar" (click)="sidebarToggle()">
        <app-feather-icons [icon]="'grid'" class="status_toggle middle"></app-feather-icons>
      </div>
    </div>

    <div class="nav-right col pull-right right-header p-0 d-flex justify-content-between">
      <div style="max-width:85%" class="d-lg-inline d-none">
        <swiper [slidesPerView]="6" [spaceBetween]="10" [loop]="true"
        [config]="config"
         [autoplay]="{
      delay: 2000
    }" class="mySwiper">
          <ng-template swiperSlide>
            <div class="carousel-cell">
              <div class="card o-hidden shadow-none bg-success m-0">
                <div class="p-2 b-r-4 card-body">
                  <h4 class="mb-0 counter"><span class="m-0 text-capitalize text-midium ">Programadas:</span>
                    <br>
                    {{block1.programada}}
                  </h4>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template swiperSlide>
            <div class="carousel-cell">
              <div class="card o-hidden shadow-none bg-primary m-0">
                <div class="p-2 b-r-4 card-body">
                  <h4 class="mb-0 counter"><span class="m-0 text-capitalize text-midium ">En Ejecución:</span>
                    <br>
                    {{block1.ejecucion}}
                  </h4>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template swiperSlide>
            <div class="carousel-cell">
              <div class="card o-hidden shadow-none bg-success m-0">
                <div class="p-2 b-r-4 card-body">
                  <h4 class="mb-0 counter"><span class="m-0 text-capitalize text-midium ">Cuadraturas Aprobadas:</span>
                    <br>
                    {{block1.aprobada}}
                  </h4>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template swiperSlide>
            <div class="carousel-cell">
              <div class="card o-hidden shadow-none bg-success m-0">
                <div class="p-2 b-r-4 card-body">
                  <h4 class="mb-0 counter"><span class="m-0 text-capitalize text-midium ">Facturadas a Cliente:</span>
                    <br>
                    {{block1.facturada}}
                  </h4>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template swiperSlide>
            <div class="carousel-cell">
              <div class="card o-hidden shadow-none bg-success m-0">
                <div class="p-2 b-r-4 card-body">
                  <h4 class="mb-0 counter"><span class="m-0 text-capitalize text-midium ">Finalizadas:</span>
                    <br>
                    {{block1.finalizada}}
                  </h4>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template swiperSlide>
            <div class="carousel-cell">
              <div class="card o-hidden shadow-none bg-danger m-0">
                <div class="p-2 b-r-4 card-body">
                  <h4 class="mb-0 counter"><span class="m-0 text-capitalize text-midium ">Anuladas:</span>
                    <br>
                    {{block1.anulada}}
                  </h4>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template swiperSlide>
            <div class="carousel-cell">
              <div class="card o-hidden shadow-none bg-success m-0">
                <div class="p-2 b-r-4 card-body">
                  <h4 class="mb-0 counter"><span class="m-0 text-capitalize text-midium ">Sin Programar:</span>
                    <br>
                    {{block2.programa}}
                  </h4>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template swiperSlide>
            <div class="carousel-cell">
              <div class="card o-hidden shadow-none bg-primary m-0">
                <div class="p-2 b-r-4 card-body">
                  <h4 class="mb-0 counter"><span class="m-0 text-capitalize text-midium ">Con Problema:</span>
                    <br>
                    {{block2.problema}}
                  </h4>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template swiperSlide>
            <div class="carousel-cell">
              <div class="card o-hidden shadow-none bg-danger m-0">
                <div class="p-2 b-r-4 card-body">
                  <h4 class="mb-0 counter"><span class="m-0 text-capitalize text-midium ">Sin Reporte:</span>
                    <br>
                    {{block2.reporte}}
                  </h4>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template swiperSlide>
            <div class="carousel-cell">
              <div class="card o-hidden shadow-none bg-danger m-0">
                <div class="p-2 b-r-4 card-body">
                  <h4 class="mb-0 counter"><span class="m-0 text-capitalize text-midium ">Cuadratura Diferencias:</span>
                    <br>
                    {{block2.diferencia}}
                  </h4>
                </div>
              </div>
            </div>
          </ng-template>
        </swiper>

      </div>
      <ul class="nav-menus">
        <li class="onhover-dropdown">
          <app-calendario (calendarChange)="setCentroFiltro($event)"></app-calendario>
        </li>
        <li class="onhover-dropdown">
          <app-notification></app-notification>
        </li>

        <li class="maximize">
          <ul class="nav-menus">
            <img class="img-fluid for-light" src="assets/images/cbb/square_20.png" alt=""
              style="vertical-align: top !important;">
          </ul>
        </li>
        <li class="profile-nav onhover-dropdown p-0">
          <app-my-account></app-my-account>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->