import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { ConfigService } from '../utils/http';

@Injectable({
  providedIn: 'root'
})
export class FileService {



  SERVICE_URL: string;

  constructor(
    private http: ConfigService) {
    this.SERVICE_URL = 'api/files/';
  }

  save(body: FormData) {
    return this.http.post<any>(`${this.SERVICE_URL}save/`, body);
  }

  fileUrl(value: any) {
    return `${environment.apiUrl}/${this.SERVICE_URL}${value}`;
  }

  getFactibilidadFiles(id: any) {
    return this.http.get<any>(`${this.SERVICE_URL}factibilidad/${id}`);
  }

  getReporteFiles(id: any) {
    return this.http.get<any>(`${this.SERVICE_URL}reportes/${id}`);
  }
}
