import { MatchRow } from './../model/match.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'match_status',
  pure: false
})
export class MatchStatusPipe implements PipeTransform {
  transform(items: MatchRow[], filter: number[]): any {
    return items.filter(item => filter.indexOf(item.estado) !== -1);
  }
}
