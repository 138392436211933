<div class="input-group w-100">
  <div class="input-group-prepend w-25"><span class="input-group-text w-100 justify-content-center">Factibilidad</span>
  </div>
  <ng-select class="prepend-select w-75" (change)="changeFn()" [(ngModel)]="factibilidad" [items]="factibilidadData"
    [addTag]="true" bindLabel="zona" bindValue="" [virtualScroll]="true" [loading]="loading" [disabled]="disabled"
    [placeholder]="'Seleccionar...'">
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
      <div style="margin: 10px 0;" class="card">
        <div class="card-body px-4 py-3">
          <h5 class="card-title text-dark" [ngOptionHighlight]="search">{{item.zona}}</h5>
          <div class="col d-flex justify-content-between mt-2">
            <h6 class="text-muted">Equipo</h6>
            <p class="card-text text-dark">
              {{getEquipo(item.equipo)}}
            </p>
          </div>
          <div class="col d-flex justify-content-between mt-2">
            <h6 class="text-muted">Vigencia</h6>
            <p class="card-text text-dark">
              {{item.desde}} a {{item.hasta}}
            </p>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-select>
</div>
