import { AfterViewInit, Component, Input, OnDestroy, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { zhCN } from 'date-fns/locale';
import { Subject } from 'rxjs';
import { SolicitudService } from '../../services/solicitud.service';

@Component({
  selector: 'app-grupo-servicio-bombeo',
  templateUrl: './grupo-servicio-bombeo.component.html',
  styleUrls: ['./grupo-servicio-bombeo.component.scss']
})

export class GrupoServicioBombeoComponent implements OnInit {
  nroSolicitudes: number = 0;
  minimoBombeo: number = 0;
  solicitudes: any[] = [];

  dataSolicitud: any[] = [];
  infoGrupo: boolean = false;
  grupoSolicitud: any;
  solicitud: any;
  solicitudesGrupo: any[] = [];
  permitirUnirse: boolean = false;
  sinGrupo: boolean = false;
  desdeBotonGuardar: boolean = false;
  solicitudConId: boolean = false;
  gruposSolicitudesBombeo: any;
  grupoSolicitudBombeo: any;
  permitirModificar: boolean = false;
  guardarSinGrupo: boolean = false
  generadoGrupo: boolean = false;
  isVieja: boolean = false;
  @Input() set solicitudes_(value: any) {
    this.permitirGuardarSinEnlazar = false;
    this.infoGrupo = false;
    this.permitirUnirse = true;

    if (value) {
      if (value.solicitud > 0) {
        this.isVieja = true;
        this.solicitudId = value.solicitud;
      } else {
        this.isVieja = false;
        this.solicitudId = 'Nueva_Solicitud';

      }
      if (value.solicitud > 0) {
        value.solicitudes.filter(x => {
          if (x.id == this.solicitudId) {
            if (x.estado !== 'ANULADA') {
              const poseeDespachos = x.despachos.find(z => z.despachado !== null);
              if (!poseeDespachos) {
                this.generadoGrupo = false;
                this.permitirUnirse = true;
              } else {
                this.generadoGrupo = true;
                this.permitirUnirse = false
              }
            } else {
              this.generadoGrupo = true;
              this.permitirUnirse = false
            }
          }
        })
      }
      this.grupoSolicitudBombeo = value?.grupoSolicitudBombeo;
      this.desdeBotonGuardar = value.buttonGuardar;
      if (value?.solicitudes) {
        this.solicitudes = value?.solicitudes;
        // id grupo, id Solicitud
        this.dataSolicitud = [];
        let grupoSolicitudesAlmacenadas = [];
        this.solicitudes.forEach(x => {
          if (x.id == this.solicitudId && value?.grupoSolicitudBombeo) {
            this.permitirModificar = x.despachos.findIndex(z => z.folio !== null || z.despachado !== null) !== -1
            x.gruposSolicitudesBombeo = value?.grupoSolicitudBombeo
          }
          const solicitudCurso = x.id == this.solicitudId ? '1' : '0';
          let index = grupoSolicitudesAlmacenadas.findIndex(z => z === x.gruposSolicitudesBombeo?.id);
          if (index === -1) {
            let arraySolicitudes = [];
            grupoSolicitudesAlmacenadas.push(x.gruposSolicitudesBombeo?.id)
            arraySolicitudes.push(x.id)
            const grupoSolicitud = { solicitudes: arraySolicitudes, solicitudCurso, gruposSolicitudesBombeo: x.gruposSolicitudesBombeo, estadoGeneralGrupo: x.estado };
            this.dataSolicitud.push(grupoSolicitud)
          } else {
            let estadoSolicitudGrupo = x.estado !== "FINALIZADA" ? x.estado : this.dataSolicitud[index].estadoGeneralGrupo

            index = this.dataSolicitud.findIndex(z => z.gruposSolicitudesBombeo?.id === x.gruposSolicitudesBombeo?.id);
            this.dataSolicitud[index].estadoGeneralGrupo = estadoSolicitudGrupo;
            this.dataSolicitud[index].solicitudes.push(x.id);
            this.dataSolicitud[index].solicitudCurso = this.dataSolicitud[index].solicitudCurso == '1' ? '1' : solicitudCurso;
          }
          if (x.id == this.solicitudId) {
            this.gruposSolicitudesBombeo = x.gruposSolicitudesBombeo;
          }
        }
        );

        if (value.solicitud == 0) {
          let arraySolicitudes = [];
          arraySolicitudes.push(this.solicitudId);
          const objTmp = { solicitudes: arraySolicitudes, solicitudCurso: '1', gruposSolicitudesBombeo: { id: 0 } };
          this.dataSolicitud.push(objTmp)
        }
      }
    }
  }
  @Output() generarRegistro = new EventEmitter<any>();
  @Output() cancelarRegistro = new EventEmitter<any>();
  solicitudId: string;


  constructor(private solicitudService: SolicitudService) {
  }
  ngOnInit(): void {
  }
  get primeraSolicitud() {
    return this.isVieja;
  }
  cancelarGrupo() {
    this.cancelarRegistro.emit(false)
  }
  guardarGrupo() {
    this.generarRegistro.emit(this.gruposSolicitudesBombeo);
  }
  permitirGuardarSinEnlazar: boolean = false;
  unirme(item) {
    this.infoGrupo = true;
    this.quitarSolicitudArray();
    item.solicitudes.push(this.solicitudId)
    item.solicitudCurso = '1';
    this.gruposSolicitudesBombeo = item.gruposSolicitudesBombeo;
  }
  quitarSolicitudArray() {
    const posicion = this.dataSolicitud.findIndex(x => x.solicitudCurso == '1')
    const posicionSolicitud = this.dataSolicitud[posicion].solicitudes.findIndex(x => x == this.solicitudId)
    if (posicion !== -1) {
      this.dataSolicitud[posicion].solicitudes.splice(posicionSolicitud, 1)
      this.dataSolicitud[posicion].solicitudCurso = '0'
    }
  }
  generarGrupo() {
    this.infoGrupo = true;
    this.generadoGrupo = true;
    this.quitarSolicitudArray();
    let solicitudes = [];
    solicitudes.push(this.solicitudId);
    this.gruposSolicitudesBombeo = { id: 0 };
    const objTmp = { solicitudes, solicitudCurso: '1', gruposSolicitudesBombeo: { id: 0 } };
    this.dataSolicitud.push(objTmp);
  }

  redeccionar(item) {
    if (isNaN(item) || item == this.solicitudId) {
      return;
    }
    window.open(`/bombeo/${item}/formulario`, '_blank');
  }
}
