import { Injectable } from '@angular/core';
import { LongPressDirective } from '@swimlane/ngx-datatable';
import { ConfigService } from '../utils/http';

@Injectable({
  providedIn: 'root'
})
export class SolicitudSapService {


  SERVICE_URL: string;

  constructor(private http: ConfigService) {
    this.SERVICE_URL = 'api/solicitud/sap/';
  }

  solicitudFinalizadaByCliente(clienteId: string) {
    return this.http.get<any[]>(`${this.SERVICE_URL}${clienteId}`);
  }

  findByCuadraturaFinalizada(solicitudId: number) {
    return this.http.get<any[]>(`${this.SERVICE_URL}cuadratura/${solicitudId}/solicitud`);
  }

  solicitarActualizacionSAP(clienteId: String) {
    return this.http.get<number>(`${this.SERVICE_URL}actualizar/${clienteId}/cliente`);
  }
  solicitarActualizacionSolicitudSAP(solicitudId: number) {
    return this.http.get<number>(`${this.SERVICE_URL}actualizar/${solicitudId}/solicitud`);
  }
  solicitarActualizacionSolicitudSAPPDF(solicitudId: number) {
    return this.http.get<number>(`${this.SERVICE_URL}actualizar/${solicitudId}/solicitud/PDF`);
  }
  findBySolicitudProgramaPlantaFacturadas(tipoDocumento, ini, end) {
    return this.http.get<any>(`${this.SERVICE_URL}buscar/documentos`, { tipoDocumento, ini, end });
  }

}
