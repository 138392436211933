<div class="d-flex flex-wrap">
  <h5>{{title}}:</h5>
  <form [formGroup]="form" (submit)="addService()" class="w-100 pt-3" *ngIf="_editable">
    <div class="col-12 d-flex pl-0">
      <div class="col-5 mb-1 form-group m-form__group">
        <div class="input-group">
          <div class="input-group-prepend"><span class="input-group-text justify-content-center">Seleccione</span>
          </div>
          <ng-select class="prepend-select w-75" formControlName="servicio" [items]="exampleData" bindLabel="nombre"
            bindValue="" [placeholder]="'Seleccione...'" [width]="'100%'" (change)="setLimit($event)">
          </ng-select>
        </div>
      </div>

      <div class="col-3">
        <div class="input-group">
          <div class="input-group-prepend"><span class="input-group-text justify-content-center">Cantidad</span>
          </div>
          <input class="form-control" placeholder="" formControlName="cantidad" type="number" [min]="0"
            [max]="maxFactibilidad">
        </div>
      </div>
      <div class="m-1"><button class="btn btn-success" type="submit">Agregar</button></div>
    </div>
  </form>
  <div class="col-12 pt-3">
    <table aria-hidden="true" #table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
      class="row-border hover text-center w-100 bootstrap" aria-describedby="">
      <thead>
        <tr>
          <th scope="col">Producto/Servicio</th>
          <th scope="col">Cantidad</th>
          <th scope="col">Unidad</th>
          <th scope="col">Acción</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
  </div>
</div>
