import { ClienteService } from './../../services/cliente.service';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Cliente } from '../../model/cliente.model';
import { Observable } from 'rxjs/internal/Observable';
import { of, Subject } from 'rxjs';
import { distinctUntilChanged, tap, switchMap, catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-cliente-selector',
  templateUrl: './cliente-selector.component.html',
  styleUrls: ['./cliente-selector.component.scss']
})
export class ClienteSelectorComponent implements OnInit {
  @ViewChild('selectorModal', { static: true }) selectorModal: ElementRef;
  _cliente: string;
  @Input() disabled = false;
  @Input() enableCommandSearch = true;
  @Input() set cliente(value: string) {
    this._cliente = value;
    if (this._cliente) {
      this.clienteService.get(value).subscribe({
        next: (e) => {
          this.clienteData = [e];
        }
      });
    }
  }
  @Output() clienteChange = new EventEmitter<any>();

  clienteData: Cliente[];
  clientesCommand: Cliente[] = [];

  searchInput$ = new Subject<string>();
  loading = false;
  searchTerm = '';
  page = 0;
  more = true;
  addTag = false;

  constructor(
    private modalService: NgbModal,
    private clienteService: ClienteService
  ) { }

  ngOnInit() {
    this.searchInput$.pipe(
      distinctUntilChanged(),
      switchMap((term) => {
        this.cleanupParams();
        this.searchTerm = term;
        this.addTag = term?.length > 5;
        return this.searchCliente();
      })).subscribe({
        next: (e) => {
          this.clienteData = e;
        }
      });
  }

  searchCliente(page = 0, size = 10) {
    this.loading = true;
    return this.clienteService.search(this.searchTerm ?? '', page, size).pipe(
      map((e) => {
        this.more = !e.last;
        return e.content;
      }),
      catchError(() => of([])), // empty list on error
      tap(() => this.loading = false)
    );
  }

  cleanupParams() {
    this.searchTerm = '';
    this.clienteData = [];
    this.page = 0;
    this.more = true;
  }

  onOpen() {
    this.cleanupParams();

    this.searchCliente(0).subscribe({
      next: (e) => {
        this.clienteData = e;
      }
    });
  }

  onScrollToEnd() {
    if (this.more) {
      this.page += 1;
      this.searchCliente(this.page).subscribe({
        next: (e) => {
          this.clienteData = this.clienteData.concat(e);
        }
      });
    }
  }

  selectCliente(clienteSelected: Cliente) {
    if (this.clienteData.findIndex(x => x.codigoSap === clienteSelected.codigoSap) === -1) {
      // requerimiento del plugin
      this.clienteData = [
        ...this.clienteData,
        {
          codigoSap: clienteSelected.codigoSap,
          nombre: clienteSelected.nombre
        }];
      this.clienteData.sort((a, b) => a.nombre.localeCompare(b.nombre));
      this._cliente = clienteSelected.codigoSap.trim();
      this.changeFn();
    }
  }

  get getAddTagPromise() {
    return this.enableCommandSearch && this.addTag ? this.addTagPromise : false;
  }

  addTagPromise = (text) => {
    this.clienteService.searchCommand(text).subscribe({
      next: (e) => {
        this.clientesCommand = e;
        const modalRef = this.modalService.open(this.selectorModal, { centered: true });
      }
    });

  }

  dismissModal(modal) {
    modal.dismiss();
    this.loading = false;
  }

  changeFn() {
    this.clienteChange.emit(this._cliente);
  }
}
