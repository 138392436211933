<h5>Pedido Hormigón:</h5>
<span *ngIf="pedido?.removeCode" class="d-flex flex-row-reverse">
  <b> {{pedido?.removeCode | removeCommand}} </b>
  <span>El programa se encuentra anulado por el siguiente
    motivo: </span>
  <app-feather-icons [icon]="'alert-triangle'"></app-feather-icons>
</span>
<div class="col-12 pt-3">
  <table aria-hidden="true" #table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
    class="row-border hover text-center w-100 bootstrap" aria-describedby="">
    <thead>
      <tr>
        <th scope="col">Estado</th>
        <th scope="col">Pedido</th>
        <th scope="col">Programa / Tren</th>
        <th scope="col">Centro</th>
        <th scope="col">Nota Venta</th>
        <th scope="col">Codigo Hormigón</th>
        <th scope="col">m³</th>
        <th scope="col" ></th>
      </tr>
    </thead>
    <tbody>
    </tbody>
  </table>
</div>


<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Modificación pedido Hormigón </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="d-flex flex-wrap pt-3">

      <div class="col-12">
        <form [formGroup]="pedidoForm" class="d-flex flex-wrap">

          <div class="col form-inline d-flex">
            <div class="input-group vw-100">
              <div class="input-group-prepend w-25"><span
                  class="input-group-text w-100 justify-content-center">Planta</span>
              </div>
              <input id="planta" formControlName="planta" type="text" class="form-control" readonly value="{{planta}}">
            </div>
          </div>
          <div class="col form-inline d-flex">
            <div class="input-group vw-100">
              <div class="input-group-prepend w-25"><span
                  class="input-group-text w-100 justify-content-center">Horario</span>
              </div>
              <input id="horarios" formControlName="horarios" type="time" class="form-control" [(ngModel)]="horario"
                min="08:00" max="20:00">
            </div>
          </div>
          <div class="col form-inline d-flex">
            <div class="input-group vw-100">
              <div class="input-group-prepend w-25"><span
                  class="input-group-text w-100 justify-content-center">Volumen</span>
              </div>
              <input id="volumen" formControlName="volumen" type="text" class="form-control" value="{{volumen}}">
            </div>
          </div>
          <div class="col form-inline d-flex" *ngIf="!distribuir">
            <div class="input-group vw-100">
              <div class="input-group-prepend w-25"><span
                  class="input-group-text w-100 justify-content-center">Camiones</span>
              </div>
              <form class=" w-75">
                <tag-input [(ngModel)]="items" [allowDupes]="true" [placeholder]="''" [secondaryPlaceholder]="'Carga'"
                [theme]="'bootstrap3-info'" [validators]="validator" [onAdding]="onAdd" 
                [ngClass]=" ng2-tag-input--focuse"
                ></tag-input>
              </form>
            </div>

          </div>

          <div class="col form-inline d-flex">
            <div class="input-group vw-100">
              <div class="input-group-prepend w-25"><span
                  class="input-group-text w-100 justify-content-center">Frecuencia</span>
              </div>
              <ng-select [(ngModel)]="frecuencia" class=" w-75" name="frecuencia"
                formControlName="frecuencia" >
                <ng-option *ngFor="let item of frecuenciaArr" [value]="item.id">{{item.id}}</ng-option>
              </ng-select>
            </div>
          </div>
          <div class="col form-inline d-flex">
            <div class="input-group vw-100">
              <div class="input-group-prepend w-25"><span
                  class="input-group-text w-100 justify-content-center">Observación</span>
              </div>
              <input id="observacion" formControlName="observacion" type="text" class="form-control" autocomplete="off" >
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="modal.dismiss('Cross click')" type="button" class="btn btn-danger text-uppercase">Cerrar</button>
    <button type="button" class="btn btn-success" [disabled]="bloquearGuardado" (click)="guardarNuevoPedido()">Confirmar</button>
  </div>

</ng-template>