<div class="input-group w-100">
  <div class="input-group-prepend w-25"><span class="input-group-text w-100 justify-content-center">Nota Venta</span>
  </div>
  <ng-select class="prepend-select w-75" (change)="changeFn()" [(ngModel)]="notaVenta" [items]="notaVentaData"
    [addTag]="true" addTagText="Buscar en Command" bindLabel="codigo" bindValue="codigo" [virtualScroll]="true"
    [loading]="loading" [placeholder]="'Seleccionar...'" [disabled]="disabled">
    <ng-template ng-header-tmp>
      <span>Actualizar las Notas de Ventas</span>
      <button class="btn" [style.color]="'darkgray'" type="button" (click)="actualizarNotaVenta()">
        <app-feather-icons [icon]="'refresh-cw'"></app-feather-icons>
      </button>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
      <div style="margin: 10px 0;" class="card">
        <div class="card-body px-4 py-3">
          <h5 class="card-title text-dark" [ngOptionHighlight]="search">{{item.codigo}}</h5>
          <div class="col d-flex justify-content-between mt-2">
            <h6 class="text-muted">Vigencia</h6>
            <p class="card-text text-dark">
              {{item.vigencia | date:'longDate'}}
            </p>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-select>
</div>
