import { Notificacion } from './../model/notificacion.model';
import { Table } from '../model/table.model';
import { Injectable } from '@angular/core';
import { ConfigService } from '../utils/http';

@Injectable({
  providedIn: 'root'
})
export class NotificacionService {
  SERVICE_URL: string;

  constructor(
    private http: ConfigService) {
    this.SERVICE_URL = 'api/notificacion/';
  }

  list(user, page, size) {
    return this.http.get<Table<Notificacion>>(`${this.SERVICE_URL}user/${user}?`, { page, size });
  }
  count(user) {
    return this.http.get<number>(`${this.SERVICE_URL}user/${user}/count`);
  }

  setSeen(notId) {
    return this.http.post<number>(`${this.SERVICE_URL}${notId}/seen`, {});
  }
}
