import { environment } from './../../../../environments/environment.prod';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public today: number = Date.now();
  version: string;

  constructor() {
    this.version = environment.version;
   }

  ngOnInit(): void {
  }

}
