<div class="input-group w-100">
  <div class="input-group-prepend w-25"><span class="input-group-text w-100 justify-content-center">Cliente</span>
  </div>
  <ng-select class="prepend-select w-75" (change)="changeFn()" [(ngModel)]="_cliente" [items]="clienteData"
    bindLabel="nombre" bindValue="codigoSap" addTagText="Buscar en Command" [addTag]="getAddTagPromise"
    [placeholder]="'Seleccionar...'" [typeToSearchText]="'Complete el nombre del cliente'"
    [notFoundText]="'Complete el nombre del cliente'" [loading]="loading" [placeholder]="'Seleccionar...'"
    (scrollToEnd)="onScrollToEnd()" (open)="onOpen()" [typeahead]="searchInput$" [disabled]="disabled">
  </ng-select>
</div>


<ng-template #selectorModal let-modal>
  <div class="modal-header">
    <h4>Clientes encontrados</h4>
  </div>
  <div class="modal-body">
    <h5>Seleccione el cliente</h5>
    <span class="text-muted" [style.font-size]="'small'">
      <app-feather-icons [icon]="'alert-triangle'"></app-feather-icons>
      Si no encuentra el deseado, debe solicitar acceso a ese cliente con su administrador.
    </span>
    <ul class="col">
      <li class="p-2 border-bottom" [style.font-size]="'larger'" [style.cursor]="'pointer'"
        *ngFor="let cliente of clientesCommand" (click)="modal.dismiss();selectCliente(cliente)">
        {{cliente.nombre}}
      </li>
    </ul>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss()">Close</button>
  </div>
</ng-template>
