import { DataTableDirective } from 'angular-datatables';
import { PedidoService } from '../../services/pedido.service';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Pedido } from '../../model/pedido.model';
import { Subject } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NotaVentaService } from '../../services/nota-venta.service';
import * as moment from 'moment';
import { parse } from 'path';
import { SolicitudDespachoService } from './../../../shared/services/solicitud-despacho.service';


declare var require;
const Swal = require('sweetalert2');
@Component({
  selector: 'app-pedido-selector',
  templateUrl: './pedido-selector.component.html',
  styleUrls: ['./pedido-selector.component.scss']
})
export class PedidoSelectorComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;
  @Input() disabled = false;
  private _pedidos: any;
  removeReason: any;
  @Input() set pedidos(value) {
    this._pedidos = value;
    if (this._pedidos) {
      this.dtTrigger.next();
    }
  }
  @Input() idSolicitud;
  @Input() despachos;
  @Input() pedido;
  private modificable: boolean;
  @Input() set modificablePedido(value) {
    this.modificable = value;
    const pedidoTmp = this._pedidos;
    this._pedidos = [];
    this.dtOptionInstance()
    this.pedidos = pedidoTmp
    try {
      this.rerender();
    } catch (ex) {

    }
  }
  @Input() notaventa: any[] = [];
  @Input() obra: any;
  @Output() pedidoChange = new EventEmitter<any>();
  @Output() selected = new EventEmitter<any>();
  @Output() modificado = new EventEmitter<any>();
  @Output() poseeTicket = new EventEmitter<any>();

  dtTrigger: Subject<any> = new Subject<any>();

  pedidoData: Pedido[];
  pedidosCommand: Pedido[] = [];

  searchInput$ = new Subject<string>();
  loading = false;
  searchTerm = '';
  page = 0;
  more = true;
  dtOptions: any;
  pedidoInfo: Pedido;
  pedidoForm: FormGroup;
  bloquearGuardado = false;
  items: any[] = [];
  minBombeo: number = 10;
  horario: any;
  distribuir: boolean = false;
  mensajeCantidad = '';
  validator = [this.soloNumeros]
  baseCI = 3;
  codigoCargaIncompleta = '';
  descripcionCargaIncompleta = '';
  cargaIncompletaPrecio = '';
  cargaMax = 7.5;
  mensaje = '';
  dataInfo: any;
  totalCargaIncompleta = 0;


  frecuenciaArr = [{ id: 5 },
  { id: 10 },
  { id: 15 },
  { id: 20 },
  { id: 25 },
  { id: 30 },
  { id: 35 },
  { id: 40 },
  { id: 45 },
  { id: 50 },
  { id: 55 },
  { id: 60 },
  { id: 65 },
  { id: 70 },
  { id: 75 },
  { id: 80 },
  { id: 85 },
  { id: 90 },
  { id: 95 },
  { id: 100 },
  { id: 105 },
  { id: 110 },
  { id: 115 },
  { id: 120 }]




  frecuencia: number;

  @ViewChild("content", { static: true }) content: ElementRef;
  constructor(
    private modalService: NgbModal,
    private pedidoService: PedidoService,
    private fb: FormBuilder,
    private notaVentaService: NotaVentaService,
    private solicitudDespachoService: SolicitudDespachoService,
  ) {

  }

  notaVentaServicios() {
    this.notaVentaService.getServicios(this.obra).subscribe({
      next:
        (commandServicios) => {
          this.notaventa = commandServicios;
        }
    });
  }

  get getRemoveReason() {
    return this.pedidoService.CODIGO_ANULADO[this.pedido?.removeCode.trim()];
  }


  ngOnInit(): void {
    this.dtOptionInstance();
  }
  dtOptionInstance() {
    const columns = [
      {
        data: 'estado',
      },
      {
        data: 'pedido',
      },
      {
        data: (d) => `P:${d.lineNumber} - T:${d.programacion}`,
      },
      {
        data: 'planta',
      },
      {
        data: 'notaVenta',
      },
      {
        data: (d) => `(${d.producto.trim()}) ${d.productoNombre.trim()}`,
        orderable: false,
      },
      {
        data: 'cantidad',
        orderable: false,
      },
      {
        visible: this.modificable,
        data: (data) => {
          return `
            <div class="d-flex">
              <a class="btn btn-primary btn-modificar-pedido" >
                <em class="icon-pencil" style="vertical-align: middle;"></em>
              </a>
            </div>`;
        }
      }
    ];
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      lengthChange: false,
      searching: false,
      select: true,
      destroy: true,
      columns,
      rowCallback: (row, data) => {
        $('.btn-modificar-pedido', row).on('click', () => {
          this.openModal(data);
        });
      }, ajax: (dataTablesParameters: any, callback) => {
        callback({
          recordsTotal: this._pedidos.length,
          recordsFiltered: this._pedidos.length,
          data: this._pedidos
        });
      },
    };
  }
  rerender(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.dataTableInstance();

  }

  dataTableInstance(ifDestroy: boolean = false) {
    this.datatableElement?.dtInstance?.then((dtInstance: DataTables.Api) => {
      let modificar = true;
      dtInstance
        .on('user-select', (e, dt, type, cell, originalEvent) => {
          if (!this.disabled) {
            // DESELECCIONAR SIEMPRE
            this.pedido = null;
            this.removeReason = null;
            this.pedidoChange.emit(this.pedido);
            if (cell[0][0].column !== 7)
              this.selected.emit(this.pedido);
            else
              modificar = false;
          }
        });
      dtInstance
        .on('select', (e, dt, type, cell, originalEvent) => {
          if (!this.disabled) {
            // DATA DE LA FILA SELECCIONADA
            const data = dt.rows(cell[0]).data().toArray();
            this.pedido = data[0];
            this.removeReason = data[0].removeCode;
            this.pedidoChange.emit(this.pedido);
            if (modificar)
              this.selected.emit(this.pedido);
          }
        });
      if (this.pedido && !this.disabled) {
        const row: any = dtInstance.row((x, data, n, e) => {
          return data.pedido === this.pedido;
        });
        row.select();
      }
    });
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  guardarNuevoPedido() {
    this.mensaje = ''
    const validaCarga = this.validaCarga()
    if (!validaCarga) {
      return;
    }
    const validaHorario = this.validaHorario(this.horario)
    if (!validaHorario) {
      return;
    }
    if (this.items.length == 0) {
      this.enviarMensaje('Es necesario ingresar una carga de camiones')
      return;
    }
    const fecuencia = this.pedidoForm.get('frecuencia').value;
    if (fecuencia == undefined || fecuencia == '') {
      this.enviarMensaje('Es necesario ingresar una frecuencia')
      return;
    }
    const observacion = this.pedidoForm.get('observacion').value;
    if (observacion.trim().length == 0) {
      this.enviarMensaje('Es necesario ingresar una observación')
      return;
    }

    const cargas = [];
    this.items.forEach(val => cargas.push(val.display));
    this.validarMinimosCargas();

    const time = moment(this.horario, 'HH:mm');

    const user = localStorage.getItem('user');
    var usr = JSON.parse(user)?.userName;
    this.calcularDistribucion();
    const pedidoObj = {
      pedido: this.dataInfo.pedido.trim(),
      fecha: this.dataInfo.fecha,
      hora: time.format('HH:mm'),
      cantidad: this.pedidoForm.get('volumen').value,
      frecuencia: this.pedidoForm.get('frecuencia').value,
      observaciones: this.pedidoForm.get('observacion').value,
      distribucionCamiones: cargas,
      programacion: this.distribuir ? 1 : 0,
      baseCI: this.baseCI,
      codigoCargaIncompleta: this.codigoCargaIncompleta,
      descripcionCargaIncompleta: this.descripcionCargaIncompleta,
      cargaIncompletaPrecio: this.cargaIncompletaPrecio.trim(),
      cargaMax: this.cargaMax,
      totalCargaIncompleta: this.totalCargaIncompleta,
      username: usr
    }
    Swal.fire({
      title: '¿Estas seguro de realizar esta acción?',
      text: ``,
      html:
        `Esta a punto de Modificar el pedido de hormigón para la solicitud #<b>${this.dataInfo.solicitudId}</b></br>`,
      type: 'warning',
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,

    }).then((result) => {
      if (result.isConfirmed) {
        this.bloquearGuardado = true;
        this.pedidoService.modificarPedido(pedidoObj).subscribe({
          next: (e) => {
            const respuesta = e;
            if (respuesta.statusCode < 200 || respuesta.statusCode >= 300) {
              Swal.fire({
                title: ``,
                text: ``,
                html:
                  `Existe un error al momento de generar la modificación`,
                icon: `warning`
              });
            } else {
              const respuestaCorrecta = JSON.parse(respuesta.datos);
              if (respuestaCorrecta.error) {
                this.enviarMensaje(respuestaCorrecta.msg)
              } else {
                this.modalService.dismissAll();
                this.modificado.emit(true);
              }
            }


          }, error: (error: any) => {
            this.bloquearGuardado = false;
            Swal.fire({
              title: ``,
              text: ``,
              html:
                `Hubo un error al momento de generar la modificación`,
              icon: `warning`
            });
          }
        });
        this.bloquearGuardado = false;
      }
    });
  }

  calcularDistribucion() {
    const vol = this.pedidoForm.get('volumen').value;
    const baseCi = this.baseCI;
    let totalCarga = 0;
    this.totalCargaIncompleta = 0;
    this.items.forEach((item, i) => {
      if (item.display) {
        totalCarga += parseFloat(item.display);
        if (i == this.items.length - 1 && totalCarga > 3 * baseCi) {
          return;
        }
        if (this.pedidoService.reglaCI[baseCi] !== undefined && this.pedidoService.reglaCI[baseCi][item.display] != undefined) {
          this.totalCargaIncompleta += this.pedidoService.reglaCI[baseCi][item.display];
        }
      }
    });
  }

  validarMinimosCargas() {
    const re2 = /000070/gi;
    const CI4 = /701340/gi;
    const CI5 = /701151/gi;
    const CI6 = /700060/gi;
    const CI65 = /701341/gi;
    const CI7 = /701121/gi;
    this.notaventa.forEach(element => {

      if (element.codigoproductosap.search(re2) === -1) {
        return;
      }
      if (element.codigoproductosap.search(CI7) > -1 && this.baseCI < 7) {
        this.baseCI = 7;
        this.codigoCargaIncompleta = element.codigoproductosap;
        this.descripcionCargaIncompleta = element.descripcionproducto;
        this.cargaIncompletaPrecio = element.precio;
        this.cargaMax = element.campo4;
        return;
      }
      if (element.codigoproductosap.search(CI65) > -1 && this.baseCI < 6.5) {
        this.baseCI = 6.5;
        this.codigoCargaIncompleta = element.codigoproductosap;
        this.descripcionCargaIncompleta = element.descripcionproducto;
        this.cargaIncompletaPrecio = element.precio;
        this.cargaMax = element.campo4;
        return;
      }
      if (element.codigoproductosap.search(CI6) > -1 && this.baseCI < 6) {
        this.baseCI = 6;
        this.codigoCargaIncompleta = element.codigoproductosap;
        this.descripcionCargaIncompleta = element.descripcionproducto;
        this.cargaIncompletaPrecio = element.precio;
        this.cargaMax = element.campo4;
        return;
      }
      if (element.codigoproductosap.search(CI5) > -1 && this.baseCI < 5) {
        this.baseCI = 5;
        this.codigoCargaIncompleta = element.codigoproductosap;
        this.descripcionCargaIncompleta = element.descripcionproducto;
        this.cargaIncompletaPrecio = element.precio;
        this.cargaMax = element.campo4;
        return;
      }
      if (element.codigoproductosap.search(CI4) > -1 && this.baseCI < 4) {
        this.baseCI = 4;
        this.codigoCargaIncompleta = element.codigoproductosap;
        this.descripcionCargaIncompleta = element.descripcionproducto;
        this.cargaIncompletaPrecio = element.precio;
        this.cargaMax = element.campo4;
      }
    });
  }
  validaCarga() {
    const volumen = this.pedidoForm.get('volumen').value
    if (!this.distribuir) {
      const total = this.items.reduce((a, b) => a + parseFloat(b.display), 0)
      const diff = volumen - total;
      if (diff != 0) {
        this.enviarMensaje('Lo programado debé ser igual al volumen total.</br> <b>Volumen solicitado</b>: ' + volumen + '</br><b>Camiones programado</b>: ' + (total).toFixed(1) + '</br><b>Diferencia</b>: ' + Math.abs(diff).toFixed(1))
        return false;
      }
    }
    const ingresoMayor = this.items.find(x => parseFloat(x.display) > this.minBombeo);
    if (ingresoMayor) {
      this.enviarMensaje('La carga programada no puede exceder a ' + this.minBombeo + ' revise la cargas Nro: ' + ingresoMayor.value + ' con valor ' + ingresoMayor.display);
      return false;
    }
    return true;
  }
  validaHorario(horario) {
    const time = moment(horario, 'HH:mm');
    const diaHoraPedido = moment(this.dataInfo.horario).toDate();
    diaHoraPedido.setHours(time.hour(), time.minute());


    const horarioActual = moment(new Date).add(0, 'hour');
    const horarioMinimo = moment('08:00 ', 'HH:mm');
    const horarioMaximo = moment('20:00', 'HH:mm');



    if (horarioActual.isAfter(diaHoraPedido)) {
      let txt = 'El Horario ingresado no puede ser antes que la fecha y hora';
      this.enviarMensaje('El Horario ingresado no puede ser antes de ' + moment(horarioActual).format("HH:mm"));
      return false;
    } else if ((time.isBefore(horarioMinimo))) {
      this.enviarMensaje('El Horario ingresado debe ser despues de las 08:00')
      return false;
    } else if (time.isAfter(horarioMaximo)) {
      this.enviarMensaje('El Horario ingresado debe ser antes de las 20:00')
      return false;
    }
    return true;
  }

  enviarMensaje(mensaje) {
    Swal.fire({
      title: ``,
      text: ``,
      html:
        `${mensaje}`,
      icon: `warning`
    });
    return;
  }
  onAdd(item) {
    if (!isNaN(item)) {
      this.items.push({ value: this.items.length + 1, display: item, readonly: false });
      return null;
    }
    return true;
  }

  changeRegla(e) {
    this.distribuir = !this.distribuir;

  }
  cargarCarga() {
    this.items = [];
    const volumen = this.pedidoForm.get('volumen').value
    const entero = Math.trunc(volumen / this.minBombeo)
    const sobrante = volumen - (entero * this.minBombeo);
    for (let i = 0; i < entero; i++) {
      this.items.push({ value: this.minBombeo, display: this.minBombeo });
    }
    if (sobrante > 0)
      this.items.push({ value: sobrante, display: sobrante });
  }
  openModal(data) {
    this.bloquearGuardado = false;
    this.dataInfo = data;
    this.buscarCargaOrden(data)
  }
  soloNumeros(control: FormControl) {
    if (isNaN(control.value)) {
      const controlValue = control.value.substr(0, control.value.length - 1)
      control.setValue(controlValue);
      return null;
    }
    return true;
  }

  buscarCargaOrden(data) {
    if (this.idSolicitud) {
      this.solicitudDespachoService.getListDespacho(
        this.idSolicitud
      ).subscribe({
        next: async (despachos) => {
          const poseeFolio = despachos.find(val => Number.isInteger(Number(val.folio)) && Number(val.folio) > 0)
          if (poseeFolio) {
            this.poseeTicket.emit(true);
          } else {
            this.buscarCarga(data);
          }
        }
      });
    } else {
      this.buscarCarga(data);
    }



  }
  buscarCarga(data) {
    const fecha = moment(data.fecha).format("YYYY-MM-DD");
    this.pedidoService.despachos(
      data.pedido.trim(),
      data.lineNumber,
      data.programacion,
      fecha
    ).subscribe({
      next: (despachos) => {
        this.items = [];
        let cargarDatos = true;
        despachos.forEach(val => {
          let poseeTicket = false;
          let poseeFolio = false;
          if (val.ticket) {
            poseeTicket = Number.isInteger(Number(val.ticket)) && Number(val.ticket) > 0;
            poseeFolio = Number.isInteger(Number(val.folio)) && Number(val.folio) > 0;
          }
          this.items.push({ value: val.ordenCarga, display: val.carga, readonly: poseeTicket })
          if (poseeFolio) {
            cargarDatos = false;
          }
        });
        if (cargarDatos)
          this.cargarModal(data);
        else
          this.poseeTicket.emit(true);

      }
    });
  }
  cargarModal(data) {

    if (!this.notaventa || this.notaventa.length == 0) {
      this.notaVentaServicios();
    }
    const campo4 = this.notaventa?.find(x => parseInt(x.codigoproductosap).toString() === parseInt(data?.producto).toString());
    if (campo4) {
      const campo4Int = parseInt(campo4.campo4);
      this.minBombeo = Math.min(7.5, campo4Int);
    }
    this.minBombeo = Math.min(7.5, this.minBombeo);
    const fechaHorario = moment(new Date(data.horario)).format('HH:mm');
    this.horario = moment(new Date(data.horario)).format('HH:mm');
    this.pedidoForm = this.fb.group({
      planta: data.planta,
      volumen: data.cantidad,
      frecuencia: data.cantidad,
      horarios: fechaHorario,
      observacion: "",
    })
    this.frecuencia = 5;
    const tamanio = 'xl';
    this.modalService.open(this.content, { centered: true, size: tamanio });
  }
  getEquipo(item) {
    const ubicacion = item.value - 1;
    const frecuencia = this.frecuencia;
    const horario = moment(this.horario, 'HH:mm').add((ubicacion * frecuencia), 'minutes');
    return moment(horario, 'HH:mm');
  }
}
