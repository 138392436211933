import { Cliente } from './../model/cliente.model';
import { Table } from './../model/table.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../utils/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {
  ESTADO = [
    {
      id: true,
      text: 'Activo'
    },
    {
      id: false,
      text: 'Deshabilitado'
    }
  ];
  SERVICE_URL: string;

  constructor(
    private http: ConfigService) {
    this.SERVICE_URL = 'api/cliente/';
  }

  list(opts: any) {
    return this.http.post<Table<Cliente>>(`${this.SERVICE_URL}`, opts);
  }

  get(id: string) {
    return this.http.get<Cliente>(`${this.SERVICE_URL}${id}`);
  }

  save(cliente: Cliente) {
    return this.http.post<any>(`${this.SERVICE_URL}guardar/`, cliente);
  }

  delete(id: string) {
    return this.http.delete(`${this.SERVICE_URL}${id}`);
  }

  getEstado(idEstado) {
    return this.ESTADO.find(x => x.id === idEstado)?.text;
  }

  search(term, page, size) {
    return this.http.get<Table<Cliente>>(`${this.SERVICE_URL}search`, { term, page, size });
  }

  searchCommand(text: string) {
    return this.http.post<Cliente[]>(`${this.SERVICE_URL}command/search/`, { text });
  }
}
