import { RemoveReasonPipe } from './pipe/remove-reason.pipe';
import { MatchResultsPipe } from './pipe/match-results.pipe';
import { FactibilidadSelectorComponent } from './components/factibilidad-selector/factibilidad-selector.component';
import { CustomDateParserFormatter, CustomAdapter } from './utils/ngDate.adapter';
import { MatchStatusPipe } from './pipe/match-status.pipe';
import { AdminTableComponent } from './components/admin-table/admin-table.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragulaModule } from 'ng2-dragula';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDropzoneModule } from 'ngx-dropzone';
// Components
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CustomizerComponent } from './components/customizer/customizer.component';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ContentComponent } from './components/layout/content/content.component';
import { FullComponent } from './components/layout/full/full.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';
import {GrupoServicioBombeoComponent} from './components/grupo-servicio-bombeo/grupo-servicio-bombeo.component'
// Header Elements Components
import { NotificationComponent } from './components/header/elements/notification/notification.component';
import { MyAccountComponent } from './components/header/elements/my-account/my-account.component';
import {CalendarioComponent} from './components/header/elements/calendario/calendario.component';
// Directives
import { DisableKeyPressDirective } from './directives/disable-key-press.directive';
import { OnlyAlphabetsDirective } from './directives/only-alphabets.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { ShowOptionsDirective } from './directives/show-options.directive';
// Services
import { ChatService } from './services/chat.service';
import { LayoutService } from './services/layout.service';
import { NavService } from './services/nav.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgSelect2Module } from 'ng-select2';
import { DataTablesModule } from 'angular-datatables';
import { ServicioSelectorComponent } from './components/servicio-selector/servicio-selector.component';
import { DespachoFormularioComponent } from './components/despacho-formulario/despacho-formulario.component';
import { ClienteSelectorComponent } from './components/cliente-selector/cliente-selector.component';
import { ObraSelectorComponent } from './components/obra-selector/obra-selector.component';
import { ProveedorSelectorComponent } from './components/proveedor-selector/proveedor-selector.component';
import { RegionSelectorComponent } from './components/region-selector/region-selector.component';
import { RegionDependienteSelectorComponent } from './components/region-dependiente-selector/region-dependiente-selector.component';
import { RegionListSelectorComponent } from './components/region-list-selector/region-list-selector.component'
import { PedidoSelectorComponent } from './components/pedido-selector/pedido-selector.component';
import { NotaVentaSelectorComponent } from './components/nota-venta-selector/nota-venta-selector.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { HttpLoaderComponent } from './components/http-loader/http-loader.component';
import { TimeUntilPipe } from './pipe/time-until.pipe';
import { SwiperModule } from "swiper/angular";

import { TagInputModule } from 'ngx-chips';
import { ProveedorSelectorBombaComponent } from './components/proveedor-selector-bomba/proveedor-selector-bomba.component';
@NgModule({
  declarations: [
    MatchStatusPipe,
    MatchResultsPipe,
    RemoveReasonPipe,

    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    CustomizerComponent,
    FeatherIconsComponent,
    FullComponent,
    ShowOptionsDirective,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    LoaderComponent,
    TapToTopComponent,
    NotificationComponent,
    CalendarioComponent,
    MyAccountComponent,
    AdminTableComponent,
    ServicioSelectorComponent,
    DespachoFormularioComponent,
    ClienteSelectorComponent,
    ObraSelectorComponent,
    ProveedorSelectorComponent,
    RegionSelectorComponent,
    FactibilidadSelectorComponent,
    PedidoSelectorComponent,
    NotaVentaSelectorComponent,
    UploadFileComponent,
    HttpLoaderComponent,
    TimeUntilPipe,
    RegionListSelectorComponent,
    RegionDependienteSelectorComponent,
    GrupoServicioBombeoComponent,
    ProveedorSelectorBombaComponent
  ],
  imports: [
    TagInputModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    DragulaModule.forRoot(),
    TranslateModule,
    NgSelect2Module,
    NgSelectModule,
    DataTablesModule,
    NgxDropzoneModule,
    SwiperModule
  ],
  providers: [
    NavService,
    ChatService,
    LayoutService,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ],
  exports: [
    TagInputModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NgSelectModule,
    NgSelect2Module,
    DataTablesModule,
    NgxDropzoneModule,

    MatchStatusPipe,
    MatchResultsPipe,
    RemoveReasonPipe,

    LoaderComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    TapToTopComponent,
    AdminTableComponent,
    ServicioSelectorComponent,
    DespachoFormularioComponent,
    ClienteSelectorComponent,
    ObraSelectorComponent,
    ProveedorSelectorComponent,
    RegionSelectorComponent,
    FactibilidadSelectorComponent,
    PedidoSelectorComponent,
    NotaVentaSelectorComponent,
    UploadFileComponent,
    HttpLoaderComponent,

    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    RegionListSelectorComponent,
    RegionDependienteSelectorComponent,
    GrupoServicioBombeoComponent,
    ProveedorSelectorBombaComponent
  ],
})
export class SharedModule { }
