<div *ngIf="httpConfig.loader"
  class="w-100 vh-100 p-fixed align-middle justify-content-center d-flex align-items-center" style="
    background-color: #d3d3d300;
    z-index: 9999999;
    min-height: calc(100vh - 80px);
    margin-left: 160px;
">
  <div class="loader-box">
    <div class="loader-7" style="width: 100px !important; height: 100px !important;"></div>
  </div>
</div>
