import { ProveedorService } from './../../services/proveedor.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Proveedor } from '../../model/proveedor.model';

@Component({
  selector: 'app-proveedor-selector',
  templateUrl: './proveedor-selector.component.html',
  styleUrls: ['./proveedor-selector.component.scss']
})
export class ProveedorSelectorComponent implements OnInit {
  @Input() proveedor: any;
  @Input() disabled = false;
  @Output() proveedorChange = new EventEmitter<any>();
  @Output() minimoBombeo = new EventEmitter<any>();

  proveedorData: Observable<Proveedor[]>;
  constructor(private proveedorService: ProveedorService) { }

  ngOnInit(): void {
    this.proveedorData = this.proveedorService.search().pipe(map((e) => e.content.filter(x => x.estado)));
  }
  changeFn() {
    this.proveedorChange.emit(this.proveedor);
    this.proveedorData.subscribe({
      next: (e) => {
        const minB =
          e.find(x => x.id === this.proveedor)?.minimoBombeo;
        this.minimoBombeo.emit(
          minB
        );
      }
    });
  }
}
