import { Routes } from '@angular/router';


export const content: Routes = [
  {
    path: '',
    loadChildren: () => import('../../components/bombeo/bombeo.module').then(m => m.BombeoModule)
  },
  {
    path: '',
    loadChildren: () => import('../../components/factibilidad/factibilidad.module').then(m => m.FactibilidadModule)
  },
  {
    path: '',
    loadChildren: () => import('../../components/cliente/cliente.module').then(m => m.ClienteModule)
  },
  {
    path: '',
    loadChildren: () => import('../../components/proveedor/proveedor.module').then(m => m.ProveedorModule)
  },
  {
    path: '',
    loadChildren: () => import('../../components/servicio/servicio.module').then(m => m.ServicioModule)
  },
  {
    path: '',
    loadChildren: () => import('../../components/reporte/reporte.module').then(m => m.ReporteModule)
  },
  {
    path: '',
    loadChildren: () => import('../../components/administracion/administracion.module').then(m => m.AdministracionModule)
  },
  {
    path: '',
    loadChildren: () => import('../../components/configuracion/configuracion.module').then(m => m.ConfiguracionModule)
  },
  {
    path: '',
    loadChildren: () => import('../../components/ejecutivo/ejecutivo.module').then(m => m.EjecutivoModule)
  },
];
