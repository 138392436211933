<div class="py-5">
  <div class="d-flex">

  </div>
  <table aria-hidden="true" #table datatable [dtOptions]="dtOptions" class="row-border hover text-center w-100 bootstrap"
    aria-describedby="">
    <thead>
      <tr>
        <th scope="col" (sort)="onSort($event)" *ngFor="let item of mapHeader | keyvalue : compare; let i=index">
          <div *ngIf="item.value.filter" [ngSwitch]="item.value.filter_type">
            <div *ngSwitchCase="'option'">
              <ng-select class="custom" [items]="item.value.filter_option" (change)="filterSelectColumn($event, i)"
                [placeholder]="'Seleccionar...'">
              </ng-select>
            </div>
            <div *ngSwitchCase="'date'" class="custom-datepicker-dropdown custom-datepicker">
              <input class="date-filter border-top-0 border-left-0 border-right-0 border-bottom border-info w-100"
                placeholder="Filtrar por {{item.value.display}}" name="{{item.key}}-id" ngbDatepicker
                #c_desde="ngbDatepicker" (click)="c_desde.toggle()" (dateSelect)="filterDateColumn($event, i)" (change)="filterDateColumn('', i)">
            </div>
            <div *ngSwitchDefault>
              <input type="text" placeholder="Filtrar por {{item.value.display}}" name="{{item.key}}-id"
                class="border-top-0 border-left-0 border-right-0 border-bottom border-info w-100"
                (keyup)="filterColumn($event, i)" />
            </div>
          </div>
        </th>
      </tr>
      <tr>
        <ng-container *ngFor="let item of mapHeader | keyvalue : compare">
          <th scope="col" sortable="{{item.key}}" (sort)="onSort($event)">
            {{item.value.display}}
          </th>
        </ng-container>
      </tr>

    </thead>
    <tbody>
    </tbody>
    <tfoot>

    </tfoot>
  </table>
</div>
