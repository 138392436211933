import { Despacho } from '../model/despacho.model';
import { Pedido } from '../model/pedido.model';
import { Table } from '../model/table.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../utils/http';

@Injectable({
  providedIn: 'root'
})
export class LogService {





  SERVICE_URL: string;

  constructor(
    private http: ConfigService) {
    this.SERVICE_URL = 'api/log/';
  }

  solicitud(solicitudId) {
    return this.http.get<any[]>(`${this.SERVICE_URL}solicitud/${solicitudId}`);
  }

  solicitudByEstado(estado, ini, end) {
    return this.http.get<any[]>(`${this.SERVICE_URL}byEstado`, { estado, ini, end });
  }

  solicitudByEstadoHistorico(estado: string) {
    return this.http.get<any[]>(`${this.SERVICE_URL}byEstadoHistorico`, { estado });
  }
}
