import { Despacho } from './../model/despacho.model';
import { Pedido } from './../model/pedido.model';
import { Table } from './../model/table.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../utils/http';

@Injectable({
  providedIn: 'root'
})
export class PedidoService {


  reglaCI = {
    4: {
      2: 2, 2.5: 1.5, 3: 1, 3.5: 0.5, 4: 0, 4.5: 0, 5: 0, 5.5: 0, 6: 0, 6.5: 0, 7: 0, 7.5: 0,
    },
    5: {
      2: 3, 2.5: 2.5, 3: 2, 3.5: 1.5, 4: 1, 4.5: 0.5, 5: 0, 5.5: 0, 6: 0, 6.5: 0, 7: 0, 7.5: 0,
    },
    6: {
      2: 4, 2.5: 3.5, 3: 3, 3.5: 2.5, 4: 2, 4.5: 1.5, 5: 1, 5.5: 0.5, 6: 0, 6.5: 0, 7: 0, 7.5: 0,
    },
    6.5: {
      2: 4.5, 2.5: 4, 3: 3.5, 3.5: 3, 4: 2.5, 4.5: 2, 5: 1.5, 5.5: 1, 6: 0.5, 6.5: 0, 7: 0, 7.5: 0,
    },
    7: {
      2: 5, 2.5: 4.5, 3: 4, 3.5: 3.5, 4: 3, 4.5: 2.5, 5: 2, 5.5: 1.5, 6: 1, 6.5: 0.5, 7: 0, 7.5: 0,
    },
  };


  CODIGO_ANULADO = {
    '1': 'CLIENTE - TIEMPO',
    '2': 'CLIENTE - ATRASO',
    '3': 'DESPACHO - ERROR',
    '4': 'CUPO DE CREDITO',
    '5': 'PROGRAMA - ERROR',
    '6': 'ESPERANDO CREDITO',
    '7': 'FALLA PLANTA- CAMION',
    '8': 'ATRASADO',
    '9': 'CUPO',
    '10': 'PRUEBA',
    '11': 'Camión en Mantención',
    '12': 'Camión Siniestrado',
    '13': 'ERROR - IMPRESION',
    '14': 'NO ESPECIFICADO',
    '40': 'Anulación WEB',
    '41': 'Anulación por modificación WEB',
    '42': 'Anulación Cbbexpress',
    '43': 'Anulación por Modificación Cbbexpress',
    '99': 'Codigo de razon para no gravable',
    '01': 'Anula Cliente',
    '02': 'Anulación interna',
  };

  SERVICE_URL: string;

  constructor(
    private http: ConfigService) {
    this.SERVICE_URL = 'api/pedido/';
  }

  search(cliente, obra, { pedido, fecha, notaVenta }) {
    return this.http.get<Pedido[]>(`${this.SERVICE_URL}search`, { cliente, obra, pedido, fecha, notaVenta });
  }

  despachos(pedido, interno, programacion, fecha) {
    return this.http.get<Despacho[]>(`${this.SERVICE_URL}${pedido}/despachos/${fecha}`, { interno, programacion });
  }

  checkPedido(pedido, interno, programacion, fecha) {
    return this.http.get<number>(`${this.SERVICE_URL}${pedido}/check/${fecha}`, { interno, programacion });
  }

  modificarPedido(pedido) {
    return this.http.post<any>(`${this.SERVICE_URL}modificar/bombeo`, pedido);
  }
}
