import { Component, Input, OnInit, EventEmitter, ElementRef, ViewChild, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileService } from '../../services/file.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
  files: File[] = [];
  @Input() title;
  @Input() btnText = 'Cargar Archivo';
  @Input() template = false;
  @Input() multiple = false;
  @Input() oldFiles = [];
  @Input() accept = '*';
  @ViewChild('selectorModal', { static: true }) selectorModal: ElementRef;
  @Output() file = new EventEmitter<any>();
  @Output() downloadTemplate = new EventEmitter<any>();

  constructor(
    private modalService: NgbModal,
    private fileService: FileService

  ) { }

  ngOnInit(): void {
  }

  getUrlFile(id) {
    return this.fileService.fileUrl(id);
  }

  onSelect(event) {
    this.files.push(...event.addedFiles);
    if (this.multiple) {
      this.file.emit(this.files);
    }
    else {
      this.file.emit(this.files[0]);
      this.modalService.dismissAll();
      this.files = [];
    }
  }

  reset(){
    this.files = [];
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.file.emit(this.multiple ? this.files : this.files[0]);
  }

  onDownloadTemplate() {
    this.downloadTemplate.emit();
  }
  openModal() {
    const modalRef = this.modalService.open(this.selectorModal, { centered: true });
  }

}
