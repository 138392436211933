import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeCommand',
  pure: false
})
export class RemoveReasonPipe implements PipeTransform {
  CODIGO_ANULADO = {
    '1': 'CLIENTE - TIEMPO',
    '2': 'CLIENTE - ATRASO',
    '3': 'DESPACHO - ERROR',
    '4': 'CUPO DE CREDITO',
    '5': 'PROGRAMA - ERROR',
    '6': 'ESPERANDO CREDITO',
    '7': 'FALLA PLANTA- CAMION',
    '8': 'ATRASADO',
    '9': 'CUPO',
    '10': 'PRUEBA',
    '11': 'Camión en Mantención',
    '12': 'Camión Siniestrado',
    '13': 'ERROR - IMPRESION',
    '14': 'NO ESPECIFICADO',
    '40': 'Anulación WEB',
    '41': 'Anulación por modificación WEB',
    '42': 'Anulación Cbbexpress',
    '43': 'Anulación por Modificación Cbbexpress',
    '99': 'Codigo de razon para no gravable',
    '01': 'Anula Cliente',
    '02': 'Anulación interna',
  };

  transform(code): any {
    return this.CODIGO_ANULADO[code.trim()];
  }
}
