import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { LogService } from './../../services/log.service';
import { SolicitudSapService } from './../../services/solicitud-sap.service';
// import Swiper core and required modules
import { SwiperComponent } from "swiper/angular";

// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination, Navigation, SwiperOptions } from "swiper";
import * as moment from 'moment';
// install Swiper modules
SwiperCore.use([Pagination, Navigation]);
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {

  public elem: any;
  public dark: boolean = this.layout.config.settings.layout_version == 'dark-only' ? true : false;
  config: SwiperOptions = {
    slidesPerView: 5,
    navigation: true,
    scrollbar: { draggable: true },
    loop: true,
    freeMode: true,
  };
  block1 = {
    anulada: 0,
    programada: 0,
    ejecucion: 0,
    aprobada: 0,
    facturada: 0,
    finalizada: 0
  };
  block2 = {
    programa: 0,
    problema: 0,
    reporte: 0,
    diferencia: 0
  };
  imageSrc = 'assets/images/cbb/GSHLogoLapiz.png';
  imagenAlt = '';

  constructor(public layout: LayoutService,
    public navServices: NavService,
    private logService: LogService,
    private SolicitudSapService: SolicitudSapService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.setCentroFiltro(null);
  }

  ngOnInit() {
    this.elem = document.documentElement;
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    this.navServices.megaMenu = false;
    this.navServices.levelMenu = false;
  }

  layoutToggle() {
    this.dark = !this.dark;
    this.layout.config.settings.layout_version = this.dark ? 'dark-only' : 'light';
  }

  searchToggle() {
    this.navServices.search = true;
  }

  languageToggle() {
    this.navServices.language = !this.navServices.language;
  }

  toggleFullScreen() {
    this.navServices.fullScreen = !this.navServices.fullScreen;
    if (this.navServices.fullScreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else {
      if (!this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  setCentroFiltro(event) {
    const end = moment().toISOString();
    let init = moment().toISOString();
    if (event !== null) {

      switch (event.id) {
        case 1:
          init = moment().add(-7, 'd').toISOString();
          break;
        case 2:
          init = moment().startOf('M').toISOString();
          break;
        case 4:
          init = moment('2021-05-01').toISOString();
          break;
        case 3:
          init = moment().startOf('year').toISOString();
          break;
        default:
          break;
      }
    }
    this.logService.solicitudByEstado('PROGRAMADA', init, end).subscribe({
      next: (e) => {
        this.block1.programada = e?.length;
      }
    });
    this.logService.solicitudByEstado('ANULADA', init, end).subscribe({
      next: (e) => {
        this.block1.anulada = e?.length;
      }
    });
    this.logService.solicitudByEstado('EJECUCION', init, end).subscribe({
      next: (e) => {
        this.block1.ejecucion = e?.length;
      }
    });
    this.logService.solicitudByEstado('MATCH_OK', init, end).subscribe({
      next: (e) => {
        this.block1.aprobada = e?.length;
      }
    });
    this.SolicitudSapService.findBySolicitudProgramaPlantaFacturadas('FACTURA', init, end).subscribe({
      next: (e) => {
        this.block1.facturada = e?.length;
      }
    });


    this.logService.solicitudByEstado('FINALIZADA', init, end).subscribe({
      next: (e) => {
        this.block1.finalizada = e?.length;
      }
    });
    /*BLOQUE 2*/
    this.logService.solicitudByEstadoHistorico('SIN_PROGRAMAR').subscribe({
      next: (e) => {
        this.block2.programa = e?.length;
      }
    });
    this.logService.solicitudByEstadoHistorico('PROBLEMA').subscribe({
      next: (e) => {
        this.block2.problema = e?.length;
      }
    });
    this.logService.solicitudByEstadoHistorico('REPORTE').subscribe({
      next: (e) => {
        this.block2.reporte = e?.length;
      }
    });
    this.logService.solicitudByEstadoHistorico('MATCH_ERROR').subscribe({
      next: (e) => {
        this.block2.diferencia = e?.length;
      }
    });
  }
}
