import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../../utils/http';

@Component({
  selector: 'app-http-loader',
  templateUrl: './http-loader.component.html',
  styleUrls: ['./http-loader.component.scss']
})
export class HttpLoaderComponent implements OnInit {

  constructor(public httpConfig: ConfigService) { }

  ngOnInit(): void {
  }

}
