import { ObraService } from './../../services/obra.service';
import { Obra } from './../../model/obra.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, EventEmitter, ElementRef, Input, Output, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Subject } from 'rxjs';
import { distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-obra-selector',
  templateUrl: './obra-selector.component.html',
  styleUrls: ['./obra-selector.component.scss']
})
export class ObraSelectorComponent implements OnInit {

  @ViewChild('selectorModal', { static: true }) selectorModal: ElementRef;
  @Input() obra: any;
  private _cliente: string;
  @Input() disabled = false;
  @Input() enableCommandSearch = true;
  @Input() set cliente(value: string) {
    this._cliente = value;
    this.obra = null;
    this.changeFn();
    this.obraData = [];
    this.searchObra().subscribe({
      next: (e) => {
        this.obraData = e;
      }
    });
  }
  @Output() obraChange = new EventEmitter<any>();
  searchTerm = '';

  obraData: Obra[];
  obrasCommand: Obra[] = [];
  searchInput$ = new Subject<string>();
  loading = false;

  more = true;
  page = 0;
  addTag = false;

  constructor(
    private modalService: NgbModal,
    private obraService: ObraService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.searchInput$.pipe(
      distinctUntilChanged(),
      tap(() => this.loading = true),
      switchMap((term) => {
        this.searchTerm = term;
        this.addTag = term?.length > 5;
        this.obraData = [];
        return this.searchObra();
      })).subscribe({
        next: (e) => {
          this.obraData = e;
        }
      });
  }

  searchObra(page = 0, size = 10) {
    this.loading = true;
    return this.obraService.search(this._cliente ?? '', this.searchTerm, Math.floor(page / size), size).pipe(
      catchError(() => of([])), // empty list on error
      tap(() => this.loading = false)
    );
  }

  cleanupParams() {
    this.searchTerm = '';
    this.obraData = [];
    this.page = 0;
    this.more = true;
  }

  onOpen() {
    this.cleanupParams();
    this.searchObra(0).subscribe({
      next: (e) => {
        this.obraData = e;
      }
    });
  }

  onScrollToEnd() {
    if (this.more) {
      this.page += 1;
      this.searchObra(this.page).subscribe({
        next: (e) => {
          e.forEach(elem => {
            if (this.obraData.findIndex(x => x.codigoSap === elem.codigoSap) === -1) {
              this.obraData.push(elem);
            }
          });
        }
      });
    }
  }

  selectObra(obraSelected: Obra) {
    if (this.obraData.findIndex(x => x.codigoSap === obraSelected.codigoSap) === -1) {
      // requerimiento del plugin
      this.obraData = [
        ...this.obraData,
        {
          codigoSap: obraSelected.codigoSap,
          nombre: obraSelected.nombre
        }];
      this.obraData.sort((a, b) => a.nombre.localeCompare(b.nombre));
      this.obra = obraSelected.codigoSap;
      this.obraChange.emit(this.obra);
    }
  }

  get getAddTagPromise() {
    return this.enableCommandSearch && this.addTag ? this.addTagPromise : false;
  }

  addTagPromise = (text) => {
    this.obraService.searchCommand(text, this._cliente ?? '').subscribe({
      next: (e) => {
        this.obrasCommand = e;
        const modalRef = this.modalService.open(this.selectorModal, { centered: true });
      }
    });
  }

  dismissModal(modal) {
    modal.dismiss();
    this.loading = false;
  }

  changeFn() {
    this.obraChange.emit(this.obra);
  }

  get getSearchText() {
    return this._cliente ? '' : 'Seleccione el cliente';
  }
}
