import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  user = '';

  constructor(public authService: AuthService) {
    this.user = authService.getUserData()?.userName.split('@')[0];
  }

  ngOnInit() {
  }

  logout() {
    this.authService.signOut();
  }
}
