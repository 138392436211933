<div class="input-group w-100">
  <div class="input-group-prepend w-25"><span class="input-group-text w-100 justify-content-center">Obra</span>
  </div>
  <ng-select class="prepend-select w-75" (change)="changeFn()" [(ngModel)]="obra" [items]="obraData" bindLabel="nombre"
    bindValue="codigoSap" addTagText="Buscar en Command" [addTag]="getAddTagPromise" [typeToSearchText]="getSearchText"
    [virtualScroll]="true" [loading]="loading" [placeholder]="'Seleccionar...'" (scrollToEnd)="onScrollToEnd()"
    (open)="onOpen()" [typeahead]="searchInput$" [notFoundText]="'Complete el nombre de la obra'" [disabled]="disabled">
  </ng-select>
</div>

<ng-template #selectorModal let-modal>
  <div class="modal-header">
    <h4>Obras encontradas</h4>
  </div>
  <div class="modal-body">
    <h5>Seleccione la obra</h5>
    <span class="text-muted" [style.font-size]="'small'">
      <app-feather-icons [icon]="'alert-triangle'"></app-feather-icons>
      Si no encuentra la deseada, debe solicitar acceso a esa obra con su administrador.
    </span>
    <ul class="col">
      <li class="p-2 border-bottom" [style.font-size]="'larger'" [style.cursor]="'pointer'"
        *ngFor="let obra of obrasCommand" (click)="dismissModal(modal);selectObra(obra)">
        {{obra.nombre}}
      </li>
    </ul>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="dismissModal(modal)">Close</button>
  </div>
</ng-template>
