<div class="notification-box" (mouseover)="listHover()">
  <app-feather-icons [icon]="'calendar'"></app-feather-icons>

</div>
<ul class="onhover-show-div" style="max-height: 75vh !important; justify-content: center">
  <li>
    <h6 class="f-18 mb-1">Rango Fechas</h6>
  </li>
  <li class="pb-1 mb-0" *ngFor="let item of dateRange" [ngClass]="{'unseen': item.vista}" (mouseover)="setSeen(item)"
    (click)="changeFn(item)">
    <input *ngIf="item.selected" type="checkbox" checked onclick="return false;"/>
    <span> {{item.text}}</span>
  </li>
  <li></li>
</ul>