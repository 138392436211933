<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div class="text-center">
            <img src="{{imageSrc}}" alt="{{imagenAlt}}" />
          </div>
          <div class="login-main">
            <form class="theme-form" [formGroup]="loginForm">
              <div class="form-group">
                <input class="form-control" type="email" required="" placeholder="Usuario" formControlName="email">
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required"
                  class="text text-danger mt-1 tx">
                  El usuario es requerido
                </div>
                <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email"
                  class="text text-danger mt-1">
                  El usuario es invalido
                </div>
              </div>
              <div class="form-group inner-addon right-addon">

                <img alt="" src="{{ show ? 'assets/images/ojo.png' : 'assets/images/ojo-tachado.png' }}"
                  class="fassAddon fas showPassword fas_login show_documentos_busqueda" (click)="showPassword()">

                  <input class="form-control "  [type]=" show ? 'text' : 'password' "
                  placeholder="Contrase&ntilde;a"  formControlName="password" required=""
                  />

              </div>

              <div class="form-group mb-0">
                <button class="btn btn-cbb-g txt-cbb-wb btn-block" [class.loader--text]="authService.showLoader"
                  [disabled]="!loginForm.valid || authService.showLoader" (click)="login(loginForm.value)"
                  type="button"><span>{{ authService.showLoader ? '' : 'Iniciar sesi&oacute;n' }}</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
