import { Notificacion } from './../../../../model/notificacion.model';
import { AuthService } from './../../../../services/auth.service';
import { NotificacionService } from './../../../../services/notificacion.service';
import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs/internal/observable/interval';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  public openNotification = false;
  notificationCount = 0;
  page = 0;
  size = 10;
  notifications: Notificacion[] = [];
  more: boolean;
  constructor(private notificacionService: NotificacionService, private authService: AuthService) { }

  ngOnInit() {
    const intervalCount = interval(300000);
    this.countNotification();
    intervalCount.subscribe(x => {
      this.countNotification();
    });

  }

  countNotification() {
    this.notificacionService.count(this.authService.getUserData().id).subscribe({
      next: (e) => {
        this.notificationCount = e;
      }
    });
  }

  toggleNotificationMobile() {
    this.openNotification = !this.openNotification;
    this.loadInit();
  }

  loadInit() {
    this.notifications = [];
    this.page = 0;
    this.more = false;
    this.getNotification();
  }

  loadMore() {
    this.page += 1;
    this.getNotification();
  }

  getNotification() {
    this.notificacionService.list(this.authService.getUserData().id, this.page, this.size).subscribe({
      next: (e) => {
        this.notifications = [...this.notifications, ...e.content];
        this.more = !e.last;
      }
    });
  }

  setSeen(notif) {
    this.notificacionService.setSeen(notif.id).subscribe({
      next: () => {
        if (!notif.vista) {
          this.notificationCount = Math.max(0, this.notificationCount - 1);
        }
        notif.vista = true;
      }
    });
  }
}
