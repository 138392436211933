import { NavService } from './nav.service';
import { User } from './../model/user.model';
import { Injectable, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../utils/http';
import { el } from 'date-fns/locale';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {

  SERVICE_URL: string;
  public userData: User;
  public showLoader = false;

  constructor(
    private client: HttpClient,
    public router: Router,
    public ngZone: NgZone,
    public toster: ToastrService,
    private cookieService: CookieService,
    private navService: NavService,
    private http: ConfigService) {
    this.SERVICE_URL = 'api/login/';
  }

  ngOnInit(): void { }

  // sign in function
  SignIn(email, password) {
    return this.http.post<any>(`${this.SERVICE_URL}`, { email, password })
      .subscribe({
        next: (result) => {
          if (result !== null) {
            this.setUserData(result);
            this.navService.getAreasByRole();
            // this.showLoader = true;
            this.router.navigate(['']);
          } else {
            this.toster.error('You have enter Wrong Email or Password.');
            // this.showLoader = false;
            this.ngZone.run(() => {
              this.router.navigate(['/auth/login']);
            });
          }
        },
        error: (error) => {
          this.toster.error('You have enter Wrong Email or Password.');
        }
      });
  }


  forgotPassword(passwordResetEmail) {
    // return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
    //   .then(() => {
    //     window.alert('Password reset email sent, check your inbox.');
    //   }).catch((error) => {
    //     window.alert(error);
    //   });
  }

  // Set user
  setUserData(user) {
    this.userData = user;
    this.cookieService.set('user', JSON.stringify(this.userData));
    localStorage.setItem('authToken', this.userData.token);
    localStorage.setItem('user', JSON.stringify(this.userData));
  }

  havePermission(permiso) {
    const user: User = JSON.parse(localStorage.getItem('user'));
    return user.permisos.findIndex(elem => elem.permiso.id === permiso) !== -1;
  }

  getCentros() {
    const user: User = JSON.parse(localStorage.getItem('user'));
    return user.centros.map((elem) => { return { id: elem.centro, text: elem.centro } });
  }

  getUserData() {
    const user: User = JSON.parse(localStorage.getItem('user'));
    return user;
  }

  // Sign out
  signOut() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    // SI HAY QUE HACER ALGO AL SIGN OUT
    // return this.afAuth.auth.signOut().then(() => {
    // this.showLoader = false;
    localStorage.clear();
    this.cookieService.deleteAll('user', '/auth/login');
    this.router.navigate(['/auth/login']);
    // });
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user != null;
  }

  // PERMISOS
  //#region Solicitud
  get aprobarCuadratura() {
    return this.havePermission(571);
  }

  get bitacoraSolicitud() {
    return this.havePermission(572);
  }

  get cargarMasivoReporte() {
    return this.havePermission(573);
  }

  get cargarReporte() {
    return this.havePermission(574);
  }

  get accionCuadratura() {
    return this.havePermission(575);
  }

  get accionConvenioMarco() {
    return this.havePermission(571);
  }

  get ingresarSolicitud() {
    return this.havePermission(576);
  }

  get resumenSolicitud() {
    return this.havePermission(577);
  }

  get monitorSolicitud() {
    return this.havePermission(578);
  }
  //#endregion

  //#region Cliente
  get monitorCliente() {
    return this.havePermission(579);
  }
  get gestionarCliente() {
    return this.havePermission(580);
  }
  //#endregion

  //#region Factibilidad
  get monitorFactibilidad() {
     return this.havePermission(583);
  }
  get gestionarFactibilidad() {
    return this.havePermission(582);
  }
  //#endregion

  //#region Proveedor
  get hesProveedor() {
    return this.havePermission(584);
  }
  get gestionarProveedor() {
    return this.havePermission(585);
  }
  get monitorProveedor() {
    return this.havePermission(586);
  }
  //#endregion
}
