import { ServicioService } from './../../services/servicio.service';
import { Service, Servicio } from './../../model/service.model';
import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

declare var require;
const Swal = require('sweetalert2');

@Component({
  selector: 'app-servicio-selector',
  templateUrl: './servicio-selector.component.html',
  styleUrls: ['./servicio-selector.component.scss']
})
export class ServicioSelectorComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() _editable;
  @Input() set editable(editable) {
    this._editable = editable;
    this.reloadTable();

  }
  @Input() flag = false;
  @Input() title: string;
  @Input() ocultarBasico = false;
  private _available: Service[];
  @Input() set available(services: any[]) {
    this._available = services;
    if (services) {
      this.exampleData = services.filter(x => !x.servicio?.basico).map(x => x?.servicio);
    }
    else {
      this.resetServicioList();
    }
  }
  public _services: Service[] = [];
  @Input() set services(services: Service[]) {

    this._services = [];
    services.forEach(x => {
      if (x.cantidad > 0) {
        if (this.minBombeoClienteSap && this.ocultarBasico == true) {
          if (x.servicio?.codigoSap !== this.minBombeoClienteSap) {
            this._services.push(x);
          }
        } else {
          this._services.push(x);
        }
      }
    }
    );
    this.reloadTable();
  }
  minBombeoClienteSap: any
  @Input() set minBombeoClienteSap_(codigoSap: any) {
    this.minBombeoClienteSap = codigoSap;
  }
  @Output() servicesChange = new EventEmitter<any>();

  @ViewChild(DataTableDirective, { static: false })
  private datatableElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject<any>();

  form: FormGroup;
  maxFactibilidad = 1000;

  dtOptions: any = {};
  example: any;
  exampleData: Servicio[];

  constructor(
    private fb: FormBuilder,
    private servicioService: ServicioService
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      servicio: [null, Validators.required],
      cantidad: ['', Validators.required],
    });

    const columns = [{
      data: 'servicio.nombre',
      orderable: false,
    },
    {
      data: 'cantidad',
      orderable: false,
    },
    {
      data: 'servicio.medida',
      orderable: false,
    },
    {
      name: 'acciones',
      orderable: false,
      data: (d, r, i, { row }, h) => this._editable && !d?.servicio?.basico ?
        `<button class="btn btn-danger btn-xs service-delete" type="button" data-row="${row}">
          <em class="icon-close" style="vertical-align: middle;"></em>
        </button>` : ''
    }];
    this.resetServicioList();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 100,
      lengthChange: false,
      searching: false,
      destroy: true,
      columns,
      rowCallback: (row, data) => {
        const self = this;
        const rowIndex = row.lastChild?.firstChild?.dataset?.row;
        $('.service-delete', row).off('click');
        $('.service-delete', row).on('click', () => {
          this.removeService(parseInt(rowIndex));
        });
        if (data.invalid) {
          row.classList.add('error-nv');
        }
      },
      ajax: (dataTablesParameters: any, callback) => {
        callback({
          recordsTotal: this._services.length,
          recordsFiltered: this._services.length,
          data: this._services.filter(x => !this.flag || x.esPrograma)
        });
      },
    };
  }

  resetServicioList() {
    // CAMBIAR
    this.servicioService.search('BOMBEO').subscribe({
      next: (e) => {
        this.exampleData = e.filter(x => !x.basico);
      }
    });
  }

  addService() {
    let servicio = this.form.value
    servicio.esPrograma = this.flag;
    if (!this.form.valid) {
      Swal.fire({
        title: `Servicios`,
        text: ``,
        html:
          `Debe seleccionar el servicio y la cantidad a agregar`,
        icon: `warning`,
      });
      return;
    }
    if (servicio.cantidad <= 0) {
      Swal.fire({
        title: `Servicios`,
        text: ``,
        html:
          `Debe seleccionar el servicio y la cantidad a agregar`,
        icon: `warning`,
      });
      return;
    }
    this._services.push(servicio);
    this.form.reset();
    this.notifyChange();
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      showConfirmButton: false,
      timer: 2000,
      toast: true,
      text: `Se han adicionado el servicio seleccionado.`,
    });

  }

  removeService(index) {
    this._services = [
      ...this._services.filter((ele, ind) => ind !== index)
    ];
    this.notifyChange();
  }

  notifyChange() {
    this.servicesChange.emit(this._services);
    this.reloadTable();
  }

  reloadTable() {
    this.dtTrigger.next();

    // this.datatableElement?.dtInstance?.then((dtInstance: DataTables.Api) => {

    //   // Destroy the table first
    //   dtInstance.destroy();
    //   // Call the dtTrigger to rerender again
    //   this.dtTrigger.next();
    // });
  }

  setLimit(selected) {
    // const factibilidad = this._available?.find(x => x?.servicio?.id === selected?.id);
    // if (factibilidad) {
    //   this.maxFactibilidad = factibilidad.cantidad ?? 1000;
    //   this.form.setValue({ cantidad: factibilidad.cantidad });
    // }
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
