import { AuthService } from './../../services/auth.service';
import { RegionService } from '../../services/region.service';
import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { map } from 'rxjs/operators';
import { FeatherIconsComponent } from '../feather-icons/feather-icons.component';
@Component({
  selector: 'app-region-list-selector',
  templateUrl: './region-list-selector.component.html',
  styleUrls: ['./region-list-selector.component.scss']
})
export class RegionListSelectorComponent implements OnInit {
  @Input() disabled = false;
  @Input() region: any[];
  @Output() regionChange = new EventEmitter<any>();
  events: any[]
  @Input() regionData: any[];
  @Input() flagReload: boolean;
  region2: any[];
  auth: AuthService;
  flag: boolean = false;;

  constructor(private regionService: RegionService, private authService: AuthService) {
    this.auth = authService;

  }
  ngOnInit(): void {
    this.regionData = this.regionData ?? this.auth.getCentros();
    this.region2 = this.regionData.map(x => x.id)
  }
  changeFn() {
    this.regionChange.emit(this.region2);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.flag !== this.flagReload) {
      this.regionData = this.auth.getCentros();
      this.region2 = [];
      this.region2 = this.regionData.map(x => x.id);
      this.flag = this.flagReload;
    }
  }
}
