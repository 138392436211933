<div class="input-group w-100">
  <div class="input-group-prepend w-25"><span class="input-group-text w-100 justify-content-center">Centro</span>
  </div>
  <ng-select class="prepend-select w-75" 
  (change)="changeFn()" 

  
  [(ngModel)]="region2" [items]="regionData"
    [placeholder]="'Seleccione...'" [disabled]="disabled" multiple="true">
  </ng-select>

</div>