import { NotaVentaService } from './../../services/nota-venta.service';
import { ObraService } from './../../services/obra.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, EventEmitter, ElementRef, Input, Output, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Subject } from 'rxjs';
import { distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-notaventa-selector',
  templateUrl: './nota-venta-selector.component.html',
  styleUrls: ['./nota-venta-selector.component.scss']
})
export class NotaVentaSelectorComponent implements OnInit {

  @Input() disabled = false;
  @Input() notaVenta: any;
  @Output() notaVentaChange = new EventEmitter<any>();
  @Output() servicios = new EventEmitter<any>();

  private _obra: number;
  @Input() set obra(value: number) {
    if (this._obra) {
      this.notaVenta = null;
    }
    this.notaVentaData = [];
    this._obra = value;
    this.searchNotaVenta();
  }


  searchTerm = '';

  notaVentaData: any[];
  loading = false;

  more = true;
  page = 0;
  constructor(
    private notaVentaService: NotaVentaService
  ) { }

  ngOnInit() {
  }

  searchNotaVenta() {
    this.loading = true;
    this.notaVentaService.getNotaVenta(this._obra).pipe(
      catchError(() => of([])), // empty list on error
      tap(() => this.loading = false)
    ).subscribe({
      next: (e) => {
        this.notaVentaData = e;
      }
    });
  }

  actualizarNotaVenta() {
    this.loading = true;
    this.notaVentaService.actualizaNotaVenta(this._obra).pipe(
      catchError(() => of([])), // empty list on error
      tap(() => this.loading = false)
    ).subscribe({
      next: (e) => {
        this.searchNotaVenta();
      }
    });
  }

  changeFn() {
    this.notaVentaChange.emit(this.notaVenta);
  }

}
