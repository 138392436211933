import { Table } from './../model/table.model';
import { Solicitud } from './../model/solicitud.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ConfigService } from '../utils/http';

@Injectable({
  providedIn: 'root'
})
export class SolicitudService {



  SERVICE_URL: string;
  ESTADO = [
    { id: 'SIN_PROGRAMAR', text: 'SIN PROGRAMAR' },
    { id: 'PROGRAMADA', text: 'PROGRAMADA' },
    { id: 'PROBLEMA', text: 'PEDIDO MODIFICADO' },
    { id: 'ANULADA', text: 'ANULADA' },
    { id: 'EJECUCION', text: 'EJECUCION' },
    { id: 'REPORTE', text: 'REPORTE PENDIENTE' },
    { id: 'MATCH_ERROR', text: 'CUADRATURA REVISION PENDIENTE' },
    { id: 'MATCH_OK', text: 'CUADRATURA APROBACION PENDIENTE' },
    { id: 'FINALIZADA', text: 'FINALIZADA' }
  ];
  ORIGEN = [
    { id: 'MANUAL', text: 'MANUAL' },
    { id: 'EXPRESS', text: 'EXPRESS' },
    { id: 'WEB', text: 'WEB' },
  ];

  constructor(
    private http: ConfigService) {
    this.SERVICE_URL = 'api/solicitud/';
  }

  list(opts: any) {
    return this.http.post<Table<Solicitud>>(`${this.SERVICE_URL}`, opts);
  }

  get(id: any) {
    return this.http.get<any>(`${this.SERVICE_URL}${id}`);
  }

  save(solicitud) {
    return this.http.post<any>(`${this.SERVICE_URL}guardar/`, solicitud);
  }

  getNombre(id: string) {
    return this.ESTADO.find(x => x.id === id)?.text;
  }

  byProveedor(proveedorId) {
    return this.http.get<Solicitud[]>(`${this.SERVICE_URL}proveedor/${proveedorId}`);
  }
  byObra(obraId) {
    return this.http.get<Solicitud[]>(`${this.SERVICE_URL}obra/${obraId}`);
  }
  solicituarAnulacionSolicitud(solicitud) {
    return this.http.post<any>(`${this.SERVICE_URL}anular`,  solicitud );

  }

  confirmaPedidoExpress(solicitud) {
    return this.http.post<any>(`${this.SERVICE_URL}confirma/express/`, solicitud);
  }

    /**
   * Obtiene  grupo de solicitudes que se parecen en obra, proveedor y fecha
   * @param obra 
   * @param proveedor 
   * @param idSolicitud 
   * @param fecha 
   * @returns 
   */
     getGrupoSolicitudes(obra, proveedor, idSolicitud, fecha) {
      return this.http.get<any>(`${this.SERVICE_URL}grupos/${obra}/${proveedor}/${idSolicitud}`, { fecha });
    }

    setEstado(idSolicitud, estado){
      return this.http.get<any>(`${this.SERVICE_URL}set_estado/${idSolicitud}/${estado}`);
    }
    
}
