import { Injectable, PipeTransform } from '@angular/core';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

import { DecimalPipe } from '@angular/common';
import { debounceTime, delay, switchMap, tap } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../directives/sortable.directive';

interface SearchResult<T> {
  data: T[];
  total: number;
}

interface State<T> {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: SortColumn<T>;
  sortDirection: SortDirection;
}

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort<T>(data: T[], column: SortColumn<T>, direction: string): T[] {
  if (direction === '' || column === '') {
    return data;
  } else {
    return [...data].sort((a, b) => {
      const res = compare(a[`${column}`], b[`${column}`]);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches<T>(obj: T, term: string) {
  for (var prop in obj) {
    let exits = obj[prop].toString().toLowerCase().includes(term.toLowerCase());
    if (exits)
      return true;
  }
  return false;
}

@Injectable({ providedIn: 'root' })
export class TableProviderService<T> {
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _data$ = new BehaviorSubject<T[]>([]);
  private _original_data$ = new BehaviorSubject<T[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  private _state: State<T> = {
    page: 1,
    pageSize: 100,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };

  constructor(
  ) {
    this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(200),
      switchMap(() => this._search()),
      delay(200),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      this._data$.next(result.data);
      this._total$.next(result.total);
    });

    this._search$.next();
  }

  get data$() { return this._data$.asObservable(); }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }

  set data$(data: Observable<T[]>) {
    this._data$.next([]);
    this.searchTerm = '';
    data.subscribe(result => {
      this._data$.next(result);
      this._original_data$.next(result);
      this._total$.next(result.length);
    });
  }

  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: string) { this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn<T>) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

  private _set(patch: Partial<State<T>>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult<T>> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;
    this._data$.next([]);
    // 1. sort
    let data = sort(this._original_data$.value, sortColumn, sortDirection);

    // 2. filter
    data = data.filter(row => matches(row, searchTerm));
    const total = data.length;

    // 3. paginate
    data = data.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ data, total });
  }
}
