import { Obra } from '../model/obra.model';
import { Table } from '../model/table.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../utils/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotaVentaService {

  SERVICE_URL: string;

  constructor(
    private http: ConfigService) {
    this.SERVICE_URL = 'api/nv/';
  }

  getNotaVenta(obra) {
    return this.http.get<any[]>(`${this.SERVICE_URL}`, { obra });
  }

  actualizaNotaVenta(obra) {
    return this.http.get<any[]>(`${this.SERVICE_URL}command`, { obra });
  }

  getServicios(obra) {
    return this.http.get<any[]>(`${this.SERVICE_URL}${obra}/sap`);
  }

  pedidoServicioSAP(obra: any, nv: string) {
    return this.http.get<any[]>(`${this.SERVICE_URL}${obra}/sap/${nv}`);
  }
  pedidoServicioSAPCommand(obra: any, nv: string, minioBombeo: String) {
    return this.http.get<any>(`${this.SERVICE_URL}${obra}/sap/${nv}/servicio/${minioBombeo}`);
  }

}
