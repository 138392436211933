<div class="d-flex flex-wrap justify-content-between">
  <h5>{{title}}:</h5>
  <div class="media" *ngIf="showRegla">
    <label class="col-form-label m-r-10">Bombeo siempre ajustado</label>
    <div class="media-body text-right icon-state">
      <label class="switch">
        <input type="checkbox" [(ngModel)]="regla" (ngModelChange)="changeRegla($event)" [disabled]="!editable"><span
          class="switch-state"></span>
      </label>
    </div>
  </div>
  <!-- TODO: DESHABILITAR MODIFICACION DE BOMBEO CUANDO ACTIVA LA REGLA Y CAMBIAR LA DATA A LA CARGA -->
  <div class="col-12 pt-3">
    <table aria-hidden="true" class="text-center table border ">
      <thead>
        <tr>
          <th scope="col">
            <span *ngIf="reporte">Folio</span>
          </th>
          <th scope="col">Hora</th>
          <th scope="col">m³ Programados</th>
          <th scope="col" *ngIf="!reporte">Ticket</th>
          <th scope="col">m³ Despachados</th>
          <th scope="col">m³ Bombeo</th>
          <th scope="col" *ngIf="reporte">m³ Reportados</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of despachos">
          <td *ngIf="!reporte">Carga #{{ item.ordenCarga }}</td>
          <td *ngIf="reporte">{{ item.folio }}</td>
          <td>{{ item.horaSalida | date:'shortTime' }}</td>
          <td>
            <ng-container *ngIf="item.carga==0; then anulado; else noanulado"></ng-container>
            <ng-template #anulado>
              Ticket Removido
            </ng-template>
            <ng-template #noanulado>
              {{item.carga | number:'1.0-1':'en-US' }}
            </ng-template>
          </td>
          <td *ngIf="!reporte">{{ item.ticket }}</td>
          <td>{{ item.despachado | number:'1.0-1':'en-US' }}</td>
          <td>
            <ng-container
              *ngIf="editable && !regla && !reporte && !item.despachado && item.carga; then edit; else noeditable">
            </ng-container>
            <ng-template #edit>
              <input type="number" class="form-control w-50 m-auto" [(ngModel)]="item.bombeo" step=".5"
                [max]="item.carga" min="0">
            </ng-template>
            <ng-template #noeditable>
              {{ (!item.despachado && reporte) ? '' : item.bombeo }}
            </ng-template>
          </td>
          <td *ngIf="reporte">
            <ng-container *ngIf="editable && item.carga; then edit; else noeditable"></ng-container>
            <ng-template #edit>
              <input type="number" class="form-control w-50 m-auto" [(ngModel)]="item.reportado" step=".5"
                [max]="item.despachado" min="0">
            </ng-template>
            <ng-template #noeditable>
              {{item.reportado}}
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
