import { FactibilidadService } from '../../services/factibilidad.service';
import { Factibilidad } from '../../model/factibilidad.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, EventEmitter, ElementRef, Input, Output, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Subject } from 'rxjs';
import { distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-factibilidad-selector',
  templateUrl: './factibilidad-selector.component.html',
  styleUrls: ['./factibilidad-selector.component.scss']
})
export class FactibilidadSelectorComponent implements OnInit {

  @Input() disabled = false;
  @Input() factibilidad: any;
  @Output() factibilidadChange = new EventEmitter<any>();
  @Output() servicios = new EventEmitter<any>();


  private _fecha: any;
  @Input() set fecha(value) {
    this.factibilidadData = [];
    this._fecha = value;
    if (!this.disabled) {
      this.factibilidad = null;
      this.searchFactibilidad();
    }
  }

  private _proveedor: number;
  @Input() set proveedor(value: number) {
    this.factibilidadData = [];
    if (!this.disabled) {
      this._proveedor = value;
      this.factibilidad = null;
      this.searchFactibilidad();
    }
  }
  private _obra: string;
  @Input() set obra(value: string) {
    this.factibilidadData = [];
    this._obra = value;
    if (!this.disabled) {
      this.factibilidad = null;
      this.searchFactibilidad();
    }
  }

  searchTerm = '';

  factibilidadData: Factibilidad[];
  loading = false;

  more = true;
  page = 0;
  constructor(
    private factibilidadService: FactibilidadService,
  ) { }

  ngOnInit() {
  }

  searchFactibilidad() {
    if (this._obra && this._proveedor && this._fecha) {
      this.loading = true;
      return this.factibilidadService.getProveedorObra(this._obra, this._proveedor, this._fecha).pipe(
        catchError(() => of([])), // empty list on error
        tap(() => this.loading = false)
      ).subscribe({
        next: (e) => {
          this.factibilidadData = e;
        }
      });
    }
  }

  changeFn() {
    this.factibilidadChange.emit(this.factibilidad);
    let servicios = this.factibilidadData.find(x => x.id === this.factibilidad.id)?.servicios ?? [];
    servicios.map(x => x.esPrograma = true);
    this.servicios.emit(servicios);
  }

  getEquipo(id) {
    return this.factibilidadService.getEquipo(id);
  }

}
