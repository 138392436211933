import { Factibilidad } from './../model/factibilidad.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Table } from '../model/table.model';
import { ConfigService } from '../utils/http';

@Injectable({
  providedIn: 'root'
})
export class FactibilidadService {

  EQUIPO = [
    {
      id: '700068',
      minimo: '700182',
      text: 'Bomba Estacionaria'
    },
    {
      id: '700069',
      minimo: '701284',
      text: 'Bomba Telescopica (MAX 36M)'
    },
    {
      id: '700072',
      minimo: '701285',
      text: 'Bomba Telescopica (> 36M)'
    },
  ];
  SERVICE_URL: string;

  constructor(
    private http: ConfigService) {
    this.SERVICE_URL = 'api/factibilidad/';
  }

  list(opts: any) {
    return this.http.post<Table<Factibilidad>>(`${this.SERVICE_URL}`, opts);
  }

  get(id: number) {
    return this.http.get<Factibilidad>(`${this.SERVICE_URL}${id}`);
  }

  save(factibilidad: Factibilidad) {
    return this.http.post<any>(`${this.SERVICE_URL}guardar/`, factibilidad);
  }

  delete(id: number) {
    return this.http.delete(`${this.SERVICE_URL}${id}`);
  }

  getProveedorObra(obra, proveedor, fecha) {
    return this.http.get<Factibilidad[]>(`${this.SERVICE_URL}proveedor/${proveedor}/obra/${obra}`, { fecha });
  }

  getEquipo(id) {
    return this.EQUIPO.find(x => x.id === id)?.text;
  }
}
