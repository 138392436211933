import { Despacho } from './../../model/despacho.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-despacho-formulario',
  templateUrl: './despacho-formulario.component.html',
  styleUrls: ['./despacho-formulario.component.scss']
})
export class DespachoFormularioComponent implements OnInit {

  @Input() title: string;
  @Input() editable = false;
  @Input() showRegla = true;
  @Input() reporte = true;
  @Input() despachos: Despacho[];

  @Input() regla = false;
  @Output() reglaChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  changeRegla(e) {
    if (e) {
      this.despachos.forEach(x => {
        if (!x.despachado) {
          x.bombeo = x.carga;
        }
      });
    }
    this.reglaChange.emit(e);
  }
}
