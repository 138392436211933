import { ProveedorServicio } from './../model/proveedor-servicios.model';
import { Proveedor } from './../model/proveedor.model';
import { Injectable } from '@angular/core';
import { ConfigService } from '../utils/http';
import { Table } from '../model/table.model';

@Injectable({
  providedIn: 'root'
})
export class ProveedorService {


  SERVICE_URL: string;

  ESTADO = [
    {
      id: true,
      text: 'Activo'
    },
    {
      id: false,
      text: 'Deshabilitado'
    }
  ];

  REGION = [
    {
      id: 'basic1',
      text: 'Basic 1'
    },
    {
      id: 'basic2',
      disabled: true,
      text: 'Basic 2'
    },
    {
      id: 'basic3',
      text: 'Basic 3'
    },
    {
      id: 'basic4',
      text: 'Basic 4'
    }
  ];

  constructor(private http: ConfigService) {
    this.SERVICE_URL = 'api/proveedor/';
  }

  list(opts: any) {
    return this.http.post<Table<Proveedor>>(`${this.SERVICE_URL}`, opts);
  }

  search() {
    return this.http.get<Table<Proveedor>>(`${this.SERVICE_URL}search/`);
  }

  searchByCentro(centro:string) {
    return this.http.get<Proveedor[]>(`${this.SERVICE_URL}activosByCenter/`,{centro});
  }

  get(id: number) {
    return this.http.get<Proveedor>(`${this.SERVICE_URL}${id}`);
  }

  save(proveedor: Proveedor) {
    return this.http.post<any>(`${this.SERVICE_URL}guardar/`, proveedor);
  }

  delete(id: number) {
    return this.http.delete(`${this.SERVICE_URL}${id}`);
  }

  getEstado(idEstado) {
    return this.ESTADO.find(x => x.id == idEstado)?.text;
  }

  getRegion(idRegion) {
    return this.REGION.find(x => x.id == idRegion)?.text;
  }

  getByNombreRUT(nombre: any, rut: any) {
    return this.http.get<Proveedor[]>(`${this.SERVICE_URL}byName/`, { nombre, rut });
  }

  getHES(proveedorId: any, dateDesde: any, dateHasta: any, centro, obra) {
    return this.http.get<ProveedorServicio[]>(`${this.SERVICE_URL}${proveedorId}/hes/${dateDesde}/${dateHasta}`, { centro, obra });
  }
  exportHES(proveedorId: any, dateDesde: any, dateHasta: any, centro, obra) {
    return this.http.get<ProveedorServicio[]>(`${this.SERVICE_URL}${proveedorId}/hes/${dateDesde}/${dateHasta}/export`, { centro, obra });
  }


}
