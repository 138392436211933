import { Table } from './../model/table.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Servicio } from '../model/service.model';
import { ConfigService } from '../utils/http';

@Injectable({
  providedIn: 'root'
})
export class ServicioService {


  SERVICE_URL: string;

  constructor(private http: ConfigService) {
    this.SERVICE_URL = 'api/servicio/';
  }

  list(opts: any) {
    return this.http.post<Table<Servicio>>(`${this.SERVICE_URL}`, opts);
  }

  search(tipo) {
    return this.http.get<Servicio[]>(`${this.SERVICE_URL}search`, { tipo });
  }

  get(id: number) {
    return this.http.get<Servicio>(`${this.SERVICE_URL}${id}`);
  }

  save(servicio: Servicio) {
    return this.http.post<any>(`${this.SERVICE_URL}guardar/`, servicio);
  }

  delete(id: number) {
    return this.http.delete(`${this.SERVICE_URL}${id}`);
  }

  basicos() {
    return this.http.get<Servicio[]>(`${this.SERVICE_URL}basicos`);
  }

  facturable(id: number){
    return this.http.get<Boolean>(`${this.SERVICE_URL}facturable/${id}`);
  }
}
