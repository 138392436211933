import { Notificacion } from '../../../../model/notificacion.model';
import { AuthService } from '../../../../services/auth.service';
import { NotificacionService } from '../../../../services/notificacion.service';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { interval } from 'rxjs/internal/observable/interval';

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.scss']
})
export class CalendarioComponent implements OnInit {
  @Output() calendarChange = new EventEmitter<any>();
  constructor() { }


  dateRange = [
    { id: 0, text: 'Hoy', vista: true, selected: true },
    { id: 1, text: 'Ultimos 7 días', vista: false, selected: false },
    { id: 2, text: 'Transcurso del mes', vista: false, selected: false },
    
    { id: 4, text: 'Histórico', vista: false, selected: false },
  ];


  ngOnInit() {
  }
  changeFn(item) {
    this.dateRange.map(z => z.selected = false);
    item.selected = true;
    this.calendarChange.emit(item);
  }
  listHover() {
    this.dateRange.map(z => z.vista = z.selected);
  }
  setSeen(notif) {
    this.dateRange.map(z => z.vista = false);
    notif.vista = true;
  }
}
