<div class="notification-box" (click)="toggleNotificationMobile()" (mouseenter)="loadInit()">
  <app-feather-icons [icon]="'bell'"></app-feather-icons>
  <span class="badge badge-pill badge-secondary" *ngIf="notificationCount">{{notificationCount}}</span>
</div>
<ul class="notification-dropdown onhover-show-div" [class.active]="openNotification"
  style="max-height: 75vh !important; overflow:auto">
  <li>
    <h6 class="f-18 mb-1">Notificaciones</h6>
  </li>
  <li class="pb-1 mb-0" *ngFor="let notif of notifications" (mouseover)="setSeen(notif)" [ngClass]="{'unseen': !notif.vista}">
    <h6>
      {{notif.titulo}}
      <span class="pull-right" style="font-size: x-small;">{{notif.creado | timeUntil}}</span>
    </h6>
    <p>
      {{notif.cuerpo}}
    </p>
    <p class="d-flex justify-content-between">
      <span>{{notif.usuario}}</span>
      <span *ngIf="notif.ref"><a href="{{notif.ref}}">Abrir</a></span>
    </p>
  </li>
  <li>
    <button *ngIf="more" class="btn btn-primary" (click)="loadMore()">Cargar anteriores</button>
  </li>
</ul>
