import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'match_results',
  pure: false
})
export class MatchResultsPipe implements PipeTransform {
  transform(items: any[], filter: number[]): any {
    return items.filter(item => filter.indexOf(item.destinatario) !== -1 && item.cantidad > 0 );
  }
}
  