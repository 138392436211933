export const environment = {
  production: true,
  apiUrl: 'https://api-gsh.dataqu.cl',
  version: '1.1.5',
  firebase: {
    apiKey: 'Your Api Key',
    authDomain: 'Your Auth Domain',
    databaseURL: 'Your Database Url',
    projectId: 'Your Project Id',
    storageBucket: 'Your StorageBucket url',
    messagingSenderId: 'Your Sender Id'
  }
};
