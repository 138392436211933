<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Grupo de Servicio de Bombeo </h4>
  <button class="btn btn-primary btn-xs service-delete" type="button" data-row="0" (click)="generarGrupo()"
    *ngIf="!generadoGrupo && primeraSolicitud">
    <em class="icon-plus" style="vertical-align: middle;"></em>&nbsp;Nuevo Grupo
  </button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="row">

      <div class="col-11">
        <table aria-hidden="true" class="row-border hover text-center w-100 bootstrap" aria-describedby="">
          <thead>
            <tr>
              <th scope="col">Solicitud</th>
              <th scope="col">Accion</th>


            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of dataSolicitud">
              <td>
                <h5>
                  <span *ngFor="let sol of item.solicitudes" class="badge badge-light" (click)="redeccionar(sol)"
                    [ngClass]="[(sol !== solicitudId)?'cursor-pointer ':'']">
                    SB #{{sol}}
                  </span>
                </h5>
              </td>
              <td *ngIf="item.solicitudCurso === '1'">
                En el Grupo
              </td>
              <td *ngIf="item.solicitudCurso === '0'">
                <button
                  [disabled]="permitirModificar || !permitirUnirse || item?.estadoGeneralGrupo=='FINALIZADA' ?'disabled' : ''"
                  (click)="unirme(item)" class="btn btn-success btn-xs" type="button">Unime</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-11" *ngIf="infoGrupo">
        <span class="d-flex flex-row-reverse">
          <span>Las modificaciones tendrán efecto una vez realice el guardado de la solicitud. </span>
          <app-feather-icons [icon]="'alert-triangle'"></app-feather-icons>
        </span>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer ">
  <div class="d-flex flex-row-reverse ">
    <button type="button" class="btn btn-success btn-cbb-g m-1" (click)="guardarGrupo()"> Aceptar </button>
    <button type="button" class="btn btn btn-danger m-1" (click)="cancelarGrupo()"> Cancelar </button>
  </div>
</div>