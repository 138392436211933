import { HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { of, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public loader = 0;
  constructor(private http: HttpClient) { }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  post<T>(service, data, contentType = 'application/json') {
    const token = localStorage.getItem('authToken');
    const user = localStorage.getItem('user');
    data.usuario = JSON.parse(user)?.name;

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token ? `Bearer ${token}` : ''
      }),
    };
    // if (contentType) {
    //   httpOptions.headers['Content-Type'] = contentType;
    // }
    this.loader += 1;
    const url = `${environment.apiUrl}/${service}`;
    return this.http.post<T>(url, data, httpOptions).pipe(
      // catchError((e) => { this.loader = Math.max(0, this.loader - 1); return throwError(e); }), // empty list on error
      // tap(() => this.loader = Math.max(0, this.loader - 1)),
      finalize(() => this.loader = Math.max(0, this.loader - 1))
    );
  }

  put<T>(service, data, contentType = 'application/json') {
    const token = localStorage.getItem('authToken');
    const user = localStorage.getItem('user');
    data.usuario = JSON.parse(user)?.name;

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token ? `Bearer ${token}` : ''
      }),
    };
    // if (contentType) {
    //   httpOptions.headers['Content-Type'] = contentType;
    // }
    this.loader += 1;
    const url = `${environment.apiUrl}/${service}`;
    return this.http.put<T>(url, data, httpOptions).pipe(
      // catchError((e) => { this.loader = Math.max(0, this.loader - 1); return throwError(e); }), // empty list on error
      // tap(() => this.loader = Math.max(0, this.loader - 1)),
      finalize(() => this.loader = Math.max(0, this.loader - 1))
    );
  }

  post_external<T>(url, data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'Content-Type: application/x-www-form-urlencoded'
      }),
    };

    this.loader += 1;
    return this.http.post<T>(url, data, httpOptions).pipe(
      // catchError((e) => { this.loader = Math.max(0, this.loader - 1); return throwError(e); }), // empty list on error
      // tap(() => this.loader = Math.max(0, this.loader - 1)),
      finalize(() => this.loader = Math.max(0, this.loader - 1))
    );
  }

  get<T>(service, params = null) {
    const token = localStorage.getItem('authToken');
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token ? `Bearer ${token}` : ''
      }),
      params
    };
    this.loader += 1;
    const url = `${environment.apiUrl}/${service}`;
    return this.http.get<T>(url, httpOptions).pipe(
      // catchError((e) => { this.loader = Math.max(0, this.loader - 1); return throwError(e); }), // empty list on error
      // tap(() => this.loader = Math.max(0, this.loader - 1)),
      finalize(() => this.loader = Math.max(0, this.loader - 1))
    );
  }

  delete(service) {
    const token = localStorage.getItem('authToken');
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: token ? `Bearer ${token}` : ''
      }),
    };

    this.loader += 1;
    const url = `${environment.apiUrl}/${service}`;
    return this.http.delete(url, httpOptions).pipe(
      // catchError((e) => { this.loader = Math.max(0, this.loader - 1); return throwError(e); }), // empty list on error
      // tap(() => this.loader = Math.max(0, this.loader - 1)),
      finalize(() => this.loader = Math.max(0, this.loader - 1))
    );
  }
}
