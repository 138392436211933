import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNowStrict } from 'date-fns'
import { es } from 'date-fns/locale'
@Pipe({
  name: 'timeUntil',
  pure: true
})
export class TimeUntilPipe implements PipeTransform {

  transform(d: any): string {
    return formatDistanceToNowStrict(new Date(d), { addSuffix: true, locale: es });
  }

}
